'use client';

import { Box, Flex, Grid, GridItem, Heading, Skeleton, Text } from '@chakra-ui/react';
import { dateFormats, formatUsd, IconMinus, IconPlus, uiColors, utcDateToLocal } from '@cryptofi/core-ui';
import { range } from 'lodash';

import { tokenIdToName } from '~/constants';
import { useGetSecurityFields } from '~/hooks';
import { AllAssetIds, TokenIdLookup, Transaction } from '~/types';
import { isCrypto } from '~/utils';

interface LatestTransactionsProps {
  transactions: Transaction[];
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
}

const AssetLabel = ({ assetId }: { assetId: AllAssetIds }) => {
  const security = useGetSecurityFields({
    ticker: assetId,
    enabled: !isCrypto(assetId),
    fields: ['name', 'image'],
  });

  return (
    <Flex h="full" grow="1" flexDir="column" justifyContent="space-between">
      <Heading size="sm"> {assetId}</Heading>

      <Text color={uiColors.sonicSilver()} fontSize="xs" noOfLines={1}>
        {isCrypto(assetId) ? tokenIdToName[assetId as TokenIdLookup] : security.data?.name}
      </Text>
    </Flex>
  );
};

//todo: consider adding an <ErrorBoundary /> here and in LatestRoboTransactions

export default function LatestTransactions({
  transactions,
  isLoading = false,
  isSuccess = false,
  isError = false,
}: LatestTransactionsProps) {
  const transactionCount = transactions?.length;

  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: '1fr 1fr',
        xl: `repeat(${transactionCount || 4}, 1fr)`,
      }}
      w={transactionCount === 1 ? { xl: '50%' } : 'full'}
      borderRight={transactionCount === 1 ? { xl: `2px solid ${uiColors.lighthouse()}` } : 'none'}
      gridGap="2px"
      bgColor={!isLoading ? uiColors.lighthouse() : 'auto'}
    >
      {isLoading &&
        range(4).map((_, i) => (
          <GridItem key={i} h="4.75rem">
            <Skeleton h="full" />
          </GridItem>
        ))}

      {isSuccess && !transactionCount && (
        <GridItem colSpan={4} h="full" textAlign="center" bgColor={uiColors.white()}>
          You have not placed any trades
        </GridItem>
      )}

      {isSuccess &&
        transactions.map((transaction) => (
          <GridItem key={transaction.orderTxId} bgColor={uiColors.white()} p="4">
            <Flex h="full" gap="4" alignItems="center" justifyContent="space-between" w="full">
              <Flex h="full" gap="4" alignItems="center">
                {transaction.transactionName === 'Buy' ? (
                  <Box borderRadius="100" bgColor={uiColors.aijiroWhite()} p="1">
                    <IconPlus h="4" w="4" __css={{ path: { fill: uiColors.mint() } }} />
                  </Box>
                ) : (
                  <Box borderRadius="100" bgColor={uiColors.redRemains()} p="1">
                    <IconMinus h="3" w="3" __css={{ path: { fill: uiColors.heroicRed() } }} />
                  </Box>
                )}

                <AssetLabel assetId={transaction.asset as AllAssetIds} />
              </Flex>

              <Flex h="full" grow="1" flexDir="column" alignItems="end" justifyContent="space-between">
                <Heading size="xs">{formatUsd({ amount: transaction.usdAmountGross || 0 })}</Heading>

                <Text size="sm">
                  {utcDateToLocal({
                    dateFormat: dateFormats.dateOnly,
                    timeString: transaction.transactTime,
                  })}
                </Text>
              </Flex>
            </Flex>
          </GridItem>
        ))}

      {(transactionCount === 3 || transactionCount === 1) && (
        <GridItem
          display={{ base: 'none', md: 'block', xl: 'none' }}
          h="full"
          w="full"
          bgColor={uiColors.white()}
        ></GridItem>
      )}

      {isError && (
        <GridItem h="full" textAlign="center" bgColor={uiColors.white()}>
          There was an error loading your transactions. Please try again later.
        </GridItem>
      )}
    </Grid>
  );
}
