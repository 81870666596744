'use client';

import { Link } from '@chakra-ui/next-js';
import { ChakraComponent, Divider, Flex, Spacer, Text } from '@chakra-ui/react';
import {
  IconArrowUp,
  IconChecking,
  IconDiamonds,
  IconInfo,
  IconOverview,
  IconSettings,
  IconSettlement,
  uiColors,
} from '@cryptofi/core-ui';
import { usePathname } from 'next/navigation';
import { Fragment } from 'react';

import { useFeatureSetEnabled, useGlobalModalContext, useOnboardingInfo, usePostTelemetryEvent } from '~/hooks';
import { uiRoutes } from '~/routes';
import { uiConstants } from '~/theme';
import { telemetryEvents } from '~/types';
import { isFalse } from '~/utils';
interface Item {
  text: string;
  IconComponent: ChakraComponent<'svg', object>;
  isActive?: boolean;
  blackAndWhiteIcon?: boolean;
  addSeparator?: boolean;
  href?: string;
  isVisible?: boolean;
  handleClick?: () => void;
}

const NavItems = () => {
  const { trackEvent } = usePostTelemetryEvent();
  const path = usePathname();
  const { isEnabled } = useFeatureSetEnabled();
  const onboardingInfo = useOnboardingInfo();
  const {
    isOnboarded,
    isOnboardedCrypto,
    isOnboardedSecurities,
    isRejectedOrPending,
    isRejectedOrPendingCrypto,
    isRejectedOrPendingSecurities,
  } = onboardingInfo;
  const { onOnboardingModalOpen } = useGlobalModalContext();

  const hasAppliedForAllProducts =
    (isOnboardedSecurities || isRejectedOrPendingSecurities) && (isOnboardedCrypto || isRejectedOrPendingCrypto);
  const hasAppliedForSomeProducts = isRejectedOrPending || isOnboarded;

  const showOnboardingLinkItem =
    isEnabled(['crypto', 'securities']) && hasAppliedForSomeProducts && !hasAppliedForAllProducts;

  const showSettingsLinkItem = isEnabled(['securities']) && isOnboardedSecurities;

  const linkItems: Item[] = [
    {
      text: isOnboardedCrypto || isRejectedOrPendingCrypto ? 'Create securities account' : 'Create crypto account',
      IconComponent: IconArrowUp,
      blackAndWhiteIcon: true,
      isVisible: showOnboardingLinkItem,
      addSeparator: showOnboardingLinkItem,
      handleClick: () => {
        onOnboardingModalOpen();
      },
    },
    {
      text: 'Home',
      IconComponent: IconOverview,
      href: uiRoutes.root(),
      isActive: path === uiRoutes.root() || path.includes('/assets'),
      handleClick: () => trackEvent(telemetryEvents.ClickedMainNavCryptoHomeClient),
    },
    {
      text: 'Transactions',
      IconComponent: IconSettlement,
      href: uiRoutes.transactionHistory(),
      isActive: path.includes(uiRoutes.transactionHistory()),
      handleClick: () => trackEvent(telemetryEvents.ClickedMainNavTransactionHistoryClient),
    },
    {
      text: 'Learn',
      IconComponent: IconInfo,
      href: uiRoutes.learn(),
      isActive: path.includes(uiRoutes.learn()),
      handleClick: () => trackEvent(telemetryEvents.ClickedMainNavLearnClient),
    },
    {
      text: 'Documents',
      IconComponent: IconChecking,
      href: uiRoutes.documents(),
      isActive: path.includes(uiRoutes.documents()),
    },
  ];

  const settingsItem =
    // any nav items after settings will be shown at the bottom of the nav column
    {
      text: 'Settings',
      IconComponent: IconSettings,
      href: uiRoutes.settings(),
      isActive: path.includes(uiRoutes.settings()),
      isVisible: showSettingsLinkItem,
      // handleClick: () => trackEvent(TelemetryClientSideEventsEnum.ClickedMainNavProfileClient),
    };

  // TODO remove feature flag once robo is generally available
  if (isEnabled(['robo'])) {
    linkItems.push(
      {
        text: 'Guided Investing',
        IconComponent: IconDiamonds,
        href: '/guided-investing',
      },
      settingsItem,
    );
  } else {
    linkItems.push(settingsItem);
  }

  const getIconCss = ({ blackAndWhiteIcon, isActive }: Partial<Item>) => {
    let fillColor;

    if (blackAndWhiteIcon) {
      fillColor = '#fff';
    } else if (isActive) {
      fillColor = 'brand.500';
    } else {
      fillColor = uiColors.sonicSilver();
    }

    return { path: { fill: fillColor } };
  };

  return (
    <>
      {linkItems
        .filter((item) => !isFalse(item.isVisible))
        .map(
          ({
            text,
            IconComponent,
            blackAndWhiteIcon,
            isActive,
            href = window.location.href,
            handleClick = () => {},
            addSeparator = false,
          }) => {
            return (
              <Fragment key={href}>
                {text === 'Settings' && <Spacer />}

                <Flex
                  as={Link}
                  alignItems="center"
                  variant={'outline'}
                  gap="2"
                  w={{ base: undefined, lg: '3rem' }}
                  h={{ base: undefined, lg: '3rem' }}
                  onClick={handleClick}
                  borderRadius="md"
                  border="solid 1px"
                  bg={isActive ? 'brand.50' : 'transparent'}
                  borderColor={isActive ? 'brand.200' : 'transparent'}
                  boxShadow={isActive ? uiConstants.boxShadow : 'none'}
                  href={href}
                  lineHeight="1"
                  position="relative"
                  padding="2"
                  transition={`border-color ${uiConstants.transitionDuration}`}
                  _hover={{
                    borderColor: !isActive && uiColors.coolElegance(),
                    textDecoration: 'none',
                    '.tooltip': {
                      display: {
                        lg: 'block',
                      },
                    },
                  }}
                >
                  <Flex
                    h="full"
                    w={{ base: undefined, lg: 'full' }}
                    alignItems="center"
                    justifyContent={{ base: 'flex-start', lg: 'center' }}
                  >
                    <IconComponent
                      h="5"
                      w="5"
                      p={blackAndWhiteIcon ? '1' : '0'}
                      borderRadius="md"
                      bg={blackAndWhiteIcon ? 'black' : 'transparent'}
                      __css={getIconCss({
                        blackAndWhiteIcon,
                        isActive,
                      })}
                    />
                  </Flex>

                  {/* inline text is displayed at smaller breakpoints when the drawer is open */}
                  <Text
                    display={{
                      lg: 'none',
                    }}
                    fontFamily="heading"
                    color={isActive ? 'brand.500' : uiColors.sonicSilver()}
                  >
                    {text}
                  </Text>

                  {/* tooltip is displayed on hover at larger breakpoints */}
                  <Text
                    className="tooltip"
                    fontFamily="heading"
                    display="none"
                    position="absolute"
                    left="3.75rem"
                    bg="white"
                    padding="2"
                    border="solid 1px"
                    boxShadow={uiConstants.boxShadow}
                    borderColor={uiColors.coolElegance()}
                    borderRadius="md"
                    fontSize="small"
                    whiteSpace="nowrap"
                    _before={{
                      content: '""',
                      bg: 'white',
                      width: '0.5rem',
                      height: '0.5rem',
                      position: 'absolute',
                      left: '-.4rem',
                      top: '.6rem',
                      border: 'solid 1px',
                      borderColor: uiColors.coolElegance(),
                      borderRight: 'none',
                      borderTop: 'none',
                      transform: 'rotate(45deg)',
                    }}
                  >
                    {text}
                  </Text>
                </Flex>

                {addSeparator && <Divider />}
              </Fragment>
            );
          },
        )}
    </>
  );
};

export default NavItems;
