'use client';

import { Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { IconCaretRight, uiColors } from '@cryptofi/core-ui';
import { useRouter } from 'next/navigation';

import { uiRoutes } from '~/routes';
import { uiConstants } from '~/theme';
import { AssetType, Investment, TableColumn } from '~/types';

interface TableProps {
  investments: Investment[];
  columns: TableColumn<Investment>[];
  assetType: AssetType;
}

const InvestmentsTable = ({ investments, columns, assetType }: TableProps) => {
  const router = useRouter();
  const getAssetDetailsRoute = (assetType: 'securities' | 'crypto', assetId: string) =>
    assetType === 'securities' ? uiRoutes.assetDetailsSecurities(assetId) : uiRoutes.assetDetailsCrypto(assetId);

  const handleRowClick = (assetId: string) => {
    router.push(getAssetDetailsRoute(assetType, assetId));
  };

  return (
    <Flex w="full" flexDir="column" gap="6">
      <Table border="1px solid" borderColor={uiColors.coolElegance()} width="100%">
        <Thead>
          <Tr
            fontFamily="heading"
            bgColor={uiColors.lighthouse()}
            color={uiColors.coolElegance()}
            border="1px solid"
            borderColor={uiColors.coolElegance()}
          >
            {columns.map((col) => (
              <Th key={col.header} px="6" isNumeric={col.isNumeric}>
                {col.header}
              </Th>
            ))}

            <Th px="4" />
          </Tr>
        </Thead>

        <Tbody fontSize="sm">
          {investments.map((investment) => (
            <Tr
              key={investment.assetId}
              onClick={() => handleRowClick(investment.assetId)}
              cursor="pointer"
              transition={`background-color ${uiConstants.transitionDuration}`}
              _hover={{ backgroundColor: uiConstants.hoverBackground }}
            >
              {columns.map((col) => (
                <Td key={col.header} px="6" isNumeric={col.isNumeric}>
                  {col.render(investment) ?? '-'}
                </Td>
              ))}

              <Td px="4">
                <IconCaretRight height="1em" width="1em" color={uiColors.sonicSilver()} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Flex>
  );
};

export default InvestmentsTable;
