import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';

import { qkRecurringOrders } from './useGetRecurringOrders';

const useDeleteRecurringOrder = () => {
  const { apiClient } = useAxiosContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (orderId: string) => {
      return apiClient?.delete(`${apiRoutes.recurringOrders()}/${orderId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [qkRecurringOrders] });
    },
  });
};

export default useDeleteRecurringOrder;
