import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { RecurringOrder } from '~/types';
import { ignoreUpperCaseKeys } from '~/utils';

interface GetRecurringOrdersProps {
  enabled?: boolean;
}

export const qkRecurringOrders = 'qk:recurringOrders';

const useGetRecurringOrders = ({ enabled = false }: GetRecurringOrdersProps) => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();

  return useQuery({
    enabled: Boolean(userAuthInfo) && enabled,
    queryKey: [qkRecurringOrders],
    queryFn: async () => {
      return apiClient
        ?.get(apiRoutes.recurringOrders())
        .then((r) => camelizeKeys(r.data, { process: ignoreUpperCaseKeys }) as RecurringOrder[]);
    },
  });
};

export default useGetRecurringOrders;
