'use client';

import { Box, Circle, Heading, HStack, Stack, VStack } from '@chakra-ui/react';
import { CfPrettyWrap, uiColors } from '@cryptofi/core-ui';

import { Investment, UserAssetAllocation } from '~/types';

type Props = {
  portfolioAllocation: UserAssetAllocation[];
  assetMetaData: Investment[] | undefined;
};

const PortfolioCompositionChart = ({ portfolioAllocation, assetMetaData }: Props) => {
  const chartColors = ['#4200FF', '#EB00FF', '#B144FF', '#FF004D', '#FFA800', '#27B0E2', '#52B729', '#E1E1E1'];

  // Combine portfolio allocation with asset metadata to get full asset details
  const enrichedPortfolioAllocation = portfolioAllocation?.map((allocation) => {
    const assetDetails = assetMetaData?.find((asset) => asset.assetId === allocation.asset);
    return {
      ...allocation,
      name: assetDetails?.assetName,
    };
  });

  const data = enrichedPortfolioAllocation
    ?.map((asset, i) => {
      return {
        asset: asset.asset,
        allocation: asset.allocationPercentage,
        name: asset.name,
        color: i < 8 ? chartColors[i] : uiColors.mint(), //defaults to mint once chartColors have been exhausted
      };
    })
    // eslint-disable-next-line no-restricted-syntax
    .sort((a, b) => Number(b.allocation) - Number(a.allocation));

  return (
    <Box w="100%">
      <VStack spacing="2" align="stretch">
        {data.map((item, index) => (
          <HStack key={index}>
            <Circle size=".75rem" bg={item.color} />

            <Heading fontSize="sm" w="3.5rem" color={uiColors.sonicSilver()}>
              {item.allocation}%
            </Heading>

            <CfPrettyWrap fontSize="sm" flex="1">
              {item.name ? item.name : item.asset}
            </CfPrettyWrap>
          </HStack>
        ))}

        <Stack direction="row" rounded="full" overflow="hidden" mt="1.5rem">
          {data.map((item, index) => (
            <Box key={index} h=".25rem" rounded="lg" bg={item.color} w={`${item.allocation}%`} />
          ))}
        </Stack>
      </VStack>
    </Box>
  );
};

export default PortfolioCompositionChart;
