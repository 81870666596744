import {
  Box,
  Checkbox,
  Divider,
  DrawerFooter,
  Flex,
  FormLabel,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import {
  CfDevConsole,
  CfDevConsoleActions,
  CfDevConsoleButton,
  CfDevConsoleDataGrid,
  CfDevConsoleFloatingButton,
  CfDevConsoleLinks,
  CfDevConsoleSystemStatus,
  formatPhoneNumber,
} from '@cryptofi/core-ui';
import dayjs from 'dayjs';
import { camelizeKeys } from 'humps';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import { useFeatureSetEnabled, useGetFiInfo, useGetSystemStatus, useGetUser, useGlobalStore } from '~/hooks';
// eslint-disable-next-line no-restricted-imports
import useAxios from '~/hooks/api/useAxios';
import { KycStatusLookup, kycStatusLookup } from '~/types';
import isDev from '~/utils/isDev';

import MenuItem from './MenuItem';
export default function DevConsoleUsage({
  setShowBreakpointDebugger,
}: {
  setShowBreakpointDebugger: Dispatch<SetStateAction<boolean>>;
}) {
  const fiInfo = useGetFiInfo();
  const user = useGetUser();
  const status = useGetSystemStatus();
  const statusData = camelizeKeys(status.data); // TODO move camelize to hook
  const axios = useAxios();
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const { isEnabled } = useFeatureSetEnabled();
  const apiUrl = process.env.NEXT_PUBLIC_API_URL ? process.env.NEXT_PUBLIC_API_URL.replace(/\/$/, '') : '';
  const { hasCopied, onCopy } = useClipboard(userAuthInfo?.idToken || '');
  const kycStates = Object.keys(kycStatusLookup).sort();
  const [enableRefetch, setEnableRefetch] = useState(true);
  const [hasSystemOverride, setHasSystemOverride] = useState(false);
  const [authBypassEnabled, setAuthBypassEnabled] = useState(false);
  const authExpiry = dayjs.unix(userAuthInfo?.expiryDate || 0).format('hh:mm a, MMM DD');
  const { isLocalDev } = isDev();
  const resetSystemState = () => {
    localStorage.removeItem('systemState');
    window.location.reload();
  };
  const getUserName = () => {
    const { firstName, middleName, lastName } = user.data?.userInfo || {};
    return [firstName, middleName, lastName].filter(Boolean).join(' ');
  };
  useEffect(() => {
    if (localStorage.getItem('systemState')) {
      setHasSystemOverride(true);
    }
  }, []);
  useEffect(() => {
    const enable = localStorage.getItem('enableRefetch');
    setEnableRefetch(!enable);
  }, [setEnableRefetch]);

  useEffect(() => {
    const cookies = new Cookies();
    const idToken = cookies.get('investifi:idToken') || localStorage.getItem('investifi:idToken');

    if (idToken) {
      setAuthBypassEnabled(true);
    }
  }, []);

  const overrideSystemState = () => {
    const isCryptoAvailable = (document.querySelector('[name=cryptoAvailable]') as HTMLInputElement)?.checked;
    const isSecuritiesAvailable = (document.querySelector('[name=securitiesAvailable]') as HTMLInputElement)?.checked;
    const securitiesTradingOpen = (document.querySelector('[name=securitiesTradingOpen]') as HTMLInputElement)?.checked;

    localStorage.setItem(
      'systemState',
      JSON.stringify({ isCryptoAvailable, isSecuritiesAvailable, securitiesTradingOpen }),
    );

    window.location.reload();
  };
  const updateKyc = (kycState: KycStatusLookup) => {
    axios
      .post(
        `${apiUrl}/v2/dev-tools/change-user-state`,
        {},
        {
          headers: {
            't-c-version': kycState === 'NOT_STARTED' ? 'none' : 'current',
            'kyc-state': kycState,
            'fi-id': user.data?.fiId,
            'user-account-id': user.data?.userAccountId,
          },
        },
      )
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error resetting onboarding');
      });
  };
  const userRows = [
    {
      label: 'Account ID',
      value: user.data?.userAccountId,
    },
    {
      label: 'Name',
      value: getUserName(),
    },
    {
      label: 'Email',
      value: user.data?.userInfo?.email,
    },
    {
      label: 'Phone',
      value: formatPhoneNumber(user.data?.userInfo?.phone || ''),
    },
    {
      label: 'Crypto KYC Status',
      value: user.data?.cryptoKycStatus,
    },
    {
      label: 'Securities KYC Status',
      value: user.data?.securitiesKycStatus,
    },
    {
      label: 'Securities Risk Profile',
      value: user.data?.securitiesRiskProfile,
    },
  ];

  const systemRows = [
    {
      label: 'Crypto Available',
      value: <CfDevConsoleSystemStatus enableRefetch={enableRefetch} status={statusData?.isCryptoAvailable} />,
    },
    {
      label: 'Securities Available',
      value: <CfDevConsoleSystemStatus enableRefetch={enableRefetch} status={statusData?.isSecuritiesAvailable} />,
    },
    {
      label: 'Securities Trading Open',
      value: <CfDevConsoleSystemStatus enableRefetch={enableRefetch} status={statusData?.securitiesTradingOpen} />,
    },
  ];
  const fiRows = [
    { label: 'FI ID', value: user.data?.fiId },
    { label: 'Name', value: fiInfo.data?.fiName },
    {
      label: 'Securities Enabled',
      value: (
        <Box as="span" fontFamily="body" color={isEnabled(['securities']) ? 'inherit' : 'gray.500'}>
          {isEnabled(['securities']) ? '✔' : '✘'}
        </Box>
      ),
    },
    {
      label: 'Crypto Enabled',
      value: (
        <Box as="span" fontFamily="body" color={isEnabled(['crypto']) ? 'inherit' : 'gray.500'}>
          {isEnabled(['crypto']) ? '✔' : '✘'}
        </Box>
      ),
    },
    {
      label: 'Robo Enabled',
      value: (
        <Box as="span" fontFamily="body" color={isEnabled(['robo']) ? 'inherit' : 'gray.500'}>
          {isEnabled(['robo']) ? '✔' : '✘'}
        </Box>
      ),
    },
  ];
  return (
    <CfDevConsole
      isLocalDev={isLocalDev}
      footer={({ uiColor }: { uiColor: string }) => (
        <DrawerFooter
          justifyContent="space-between"
          fontSize="11px"
          borderTop="dashed 1px"
          borderColor={`${uiColor}.800`}
        >
          <Flex flexDir="column">
            <Text>auth expiry: {authExpiry}</Text>
          </Flex>
        </DrawerFooter>
      )}
      consoleButton={({
        uiColor,
        isOpen,
        hideButton,
        onOpen,
      }: {
        uiColor: string;
        onClose: () => void;
        isOpen: boolean;
        hideButton: boolean;
        onOpen: () => void;
      }) => (
        <CfDevConsoleFloatingButton
          hideButton={isOpen || hideButton}
          enableRefetch={enableRefetch}
          hasSystemOverride={hasSystemOverride}
          authBypassEnabled={authBypassEnabled}
          onOpen={onOpen}
          uiColor={uiColor}
        />
      )}
    >
      {({ uiColor, onClose }: { uiColor: string; onClose: () => void }) => (
        <>
          <CfDevConsoleActions
            isLocalDev={isLocalDev}
            uiColor={uiColor}
            idToken={userAuthInfo?.idToken}
            enableRefetch={enableRefetch}
            setEnableRefetch={setEnableRefetch}
            setShowBreakpointDebugger={setShowBreakpointDebugger}
            onDrawerClose={onClose}
          />

          <CfDevConsoleDataGrid
            data={userRows}
            heading="User Info"
            uiColor={uiColor}
            buttons={
              <>
                <CfDevConsoleButton onClick={onCopy} uiColor={uiColor}>
                  <>
                    {hasCopied && (
                      <span
                        style={{
                          position: 'absolute',
                          left: 0,
                          width: '100%',
                          textAlign: 'center',
                        }}
                      >
                        Copied
                      </span>
                    )}

                    <span style={{ visibility: hasCopied ? 'hidden' : 'visible' }}>Copy token</span>
                  </>
                </CfDevConsoleButton>

                <CfDevConsoleButton
                  // TODO: remove all instances of showRiskToleranceQuestionnaire
                  onClick={() => {
                    // toggle showRiskToleranceQuestionnaire flag
                    sessionStorage.setItem(
                      'showRiskToleranceQuestionnaire',
                      sessionStorage.getItem('showRiskToleranceQuestionnaire') !== 'true' ? 'true' : 'false',
                    );

                    // reload page
                    window.location.reload();
                  }}
                  uiColor={uiColor}
                >
                  <>
                    {sessionStorage.getItem('showRiskToleranceQuestionnaire') !== 'true' ? (
                      <span>Force Show Robo RTQ</span>
                    ) : (
                      <span>Undo Force Show Robo RTQ</span>
                    )}
                  </>
                </CfDevConsoleButton>

                <Menu>
                  <MenuButton as={CfDevConsoleButton} uiColor={uiColor}>
                    Change KYC state
                  </MenuButton>

                  <MenuList bg="black" borderColor={`${uiColor}.500`} fontSize="xs">
                    <MenuItem
                      kycState={'NOT_STARTED' as KycStatusLookup}
                      onClick={() => {
                        updateKyc('NOT_STARTED' as KycStatusLookup);
                      }}
                    />

                    <MenuItem
                      kycState={'APPROVED' as KycStatusLookup}
                      onClick={() => {
                        updateKyc('APPROVED' as KycStatusLookup);
                      }}
                    />

                    <Divider my="2" />

                    {kycStates
                      .filter((state) => state !== 'APPROVED' && state !== 'NOT_STARTED')
                      .map((state) => {
                        return (
                          <MenuItem
                            key={state}
                            kycState={state as KycStatusLookup}
                            onClick={() => {
                              updateKyc(state as KycStatusLookup);
                            }}
                          />
                        );
                      })}
                  </MenuList>
                </Menu>
              </>
            }
          />

          <CfDevConsoleDataGrid data={fiRows} heading="FI Info" uiColor={uiColor} />

          <CfDevConsoleDataGrid
            data={systemRows}
            heading="System Info"
            uiColor={uiColor}
            buttons={
              <>
                {hasSystemOverride && (
                  <CfDevConsoleButton
                    uiColor={uiColor}
                    onClick={resetSystemState}
                    outline="solid 1px"
                    position="relative"
                    _after={{
                      bg: 'purple.500',
                      borderRadius: 'full',
                      width: '3',
                      height: '3',
                      position: 'absolute',
                      top: '-0.25rem',
                      right: '-0.25rem',
                      zIndex: '1',
                      content: '""',
                    }}
                  >
                    Clear override
                  </CfDevConsoleButton>
                )}

                {!hasSystemOverride && (
                  <Menu>
                    <MenuButton as={CfDevConsoleButton} uiColor={uiColor}>
                      Override system state
                    </MenuButton>

                    <MenuList
                      bg="black"
                      borderColor={`${uiColor}.500`}
                      fontSize="xs"
                      textTransform="uppercase"
                      px="4"
                      py="3"
                    >
                      <Flex mb="2" flexDir="column" gap="1">
                        <FormLabel fontFamily="mono" color={`${uiColor}.500`} fontSize="xs" cursor="pointer" w="full">
                          <Checkbox
                            size="sm"
                            mr="2"
                            name="cryptoAvailable"
                            className={`dev-checkbox ${uiColor}`}
                            defaultChecked={statusData?.isCryptoAvailable}
                          />
                          Crypto Available
                        </FormLabel>

                        <FormLabel
                          fontFamily="mono"
                          color={`${uiColor}.500`}
                          fontSize="xs"
                          cursor="pointer"
                          w="full"
                          className={`dev-checkbox ${uiColor}`}
                        >
                          <Checkbox
                            size="sm"
                            mr="2"
                            name="securitiesAvailable"
                            defaultChecked={statusData?.isSecuritiesAvailable}
                          />
                          Securities available
                        </FormLabel>

                        <FormLabel
                          fontFamily="mono"
                          color={`${uiColor}.500`}
                          fontSize="xs"
                          cursor="pointer"
                          w="full"
                          className={`dev-checkbox ${uiColor}`}
                        >
                          <Checkbox
                            size="sm"
                            mr="2"
                            name="securitiesTradingOpen"
                            defaultChecked={statusData?.securitiesTradingOpen}
                          />
                          Securities trading open
                        </FormLabel>
                      </Flex>

                      <CfDevConsoleButton uiColor={uiColor} w="full" onClick={overrideSystemState}>
                        Apply
                      </CfDevConsoleButton>
                    </MenuList>
                  </Menu>
                )}
              </>
            }
          />

          <CfDevConsoleLinks uiColor={uiColor} />
        </>
      )}
    </CfDevConsole>
  );
}
