import { useMutation, useQueryClient } from '@tanstack/react-query';

import { qkUser } from '~/hooks';
import { RiskToleranceLookup } from '~/types';

interface RiskToleranceResponse {
  success: boolean;
  riskTolerance: RiskToleranceLookup;
}

const usePostSecuritiesRiskTolerance = () => {
  const queryClient = useQueryClient();

  return useMutation<RiskToleranceResponse, Error, RiskToleranceLookup>({
    // TODO: Replace with actual API endpoint once it's complete
    mutationFn: async (riskTolerance: RiskToleranceLookup) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      return {
        success: true,
        riskTolerance: riskTolerance,
      } as RiskToleranceResponse;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.includes(qkUser);
        },
      });
    },
    onError: (error) => {
      console.error('Error setting risk tolerance:', error);
    },
  });
};

export default usePostSecuritiesRiskTolerance;
