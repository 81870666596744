import { Flex } from '@chakra-ui/react';
import { CfInput, IconClose, IconSearch } from '@cryptofi/core-ui';
import { SetStateAction } from 'react';

interface SearchInputProps {
  searchTerm: string;
  onChange: (value: SetStateAction<string>) => void;
  name: string;
  ariaLabel?: string;
}

const SearchInput = ({ searchTerm, onChange, name, ariaLabel = 'Search' }: SearchInputProps) => (
  <Flex w={{ base: '100%', md: '300px' }} flexShrink={0}>
    <CfInput
      width="100%"
      name={name}
      placeholder="Search"
      value={searchTerm}
      onChange={(e) => onChange(e.target.value)}
      aria-label={ariaLabel}
      leftElement={<IconSearch />}
      rightElement={
        searchTerm ? (
          <IconClose
            aria-label="Clear search"
            cursor="pointer"
            onClick={() => {
              onChange('');
            }}
          />
        ) : undefined
      }
    />
  </Flex>
);

export default SearchInput;
