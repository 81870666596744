'use client';

import { Flex, Text } from '@chakra-ui/react';
import { aggregateAmounts, uiColors } from '@cryptofi/core-ui';
import Big from 'big.js';
import { useMemo } from 'react';
import { Cell, Pie, PieChart } from 'recharts';

import { SecurityAsset } from '~/types';

interface Props {
  investments: Array<{
    assetId: string;
    amountUsd: number;
  }>;
  securityPrices: {
    data?: SecurityAsset[];
    isLoading: boolean;
    isError: boolean;
  };
}

interface DistributionItem {
  name: string;
  value: number;
}

const InvestmentDistribution = ({ investments, securityPrices }: Props) => {
  const distribution = useMemo<DistributionItem[] | null>(() => {
    if (!securityPrices.data || investments.length === 0) {
      return null;
    }

    const securityCodeMap = new Map(securityPrices.data.map((security) => [security.securitiesTicker, security.code]));

    const calculateTotal = (codes: string[]) => {
      const amounts = investments
        .filter((inv) => {
          const code = securityCodeMap.get(inv.assetId);
          return codes.includes(code || '');
        })
        .map((inv) => inv.amountUsd ?? 0);

      return aggregateAmounts(amounts);
    };

    const etfTotal = new Big(calculateTotal(['ETF', 'ETC']));
    const stockTotal = new Big(calculateTotal(['EQS']));

    const total = etfTotal.plus(stockTotal).gt(0) ? etfTotal.plus(stockTotal) : new Big(1);

    return [
      {
        name: 'ETFs',
        value: parseFloat(etfTotal.div(total).times(100).round(0).toString()),
      },
      {
        name: 'Stocks',
        value: parseFloat(stockTotal.div(total).times(100).round(0).toString()),
      },
    ];
  }, [investments, securityPrices.data]);

  if (!distribution) {
    return null;
  }

  const [etfs, stocks] = distribution;

  const etfsText = `${etfs.name} (${new Big(etfs.value).round().toNumber()}%)`;
  const stocksText = `${stocks.name} (${new Big(stocks.value).round().toNumber()}%)`;

  const pieData = [etfs, stocks];

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bg={uiColors.lighthouse()}
      p="5"
      borderRadius="lg"
      borderColor={uiColors.coolElegance()}
      ml="5"
      h="12"
    >
      <Flex alignItems="center" gap="3">
        <Text fontFamily="heading" fontSize="xs">
          {etfsText}
        </Text>

        <Flex alignItems="center" transform="translateY(-2px)" w="8">
          <PieChart width={36} height={36}>
            <Pie
              data={pieData}
              cx={16}
              cy={16}
              innerRadius={0}
              outerRadius={16}
              dataKey="value"
              startAngle={180}
              endAngle={-180}
            >
              {pieData.map((entry) => (
                <Cell key={`cell-${entry.name}`} fill={entry.name === 'ETFs' ? 'transparent' : uiColors.black()} />
              ))}
            </Pie>
          </PieChart>
        </Flex>

        <Text fontFamily="heading" fontSize="xs">
          {stocksText}
        </Text>
      </Flex>
    </Flex>
  );
};

export default InvestmentDistribution;
