'use client';

import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverBodyProps,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
} from '@chakra-ui/react';
import { uiColors } from '@cryptofi/core-ui';

import { uiConstants } from '~/theme';

const PopoverTooltip = ({
  triggerText,
  triggerIcon,
  popoverBody,
  popoverContentProps,
  popoverProps,
  popoverBodyProps,
  trigger = 'hover',
}: {
  triggerText: React.ReactNode;
  triggerIcon: React.ReactNode;
  popoverBody: React.ReactNode;
  popoverProps?: PopoverProps;
  popoverContentProps?: PopoverContentProps;
  popoverBodyProps?: PopoverBodyProps;
  trigger?: 'hover' | 'click';
}) => {
  return (
    <Popover trigger={trigger} {...popoverProps}>
      <PopoverTrigger>
        <Button
          variant="unstyled"
          w="fit-content"
          px="2"
          py="1"
          mb="6"
          backgroundColor={uiColors.lighthouse()}
          border="1px solid"
          borderColor={uiColors.lighthouse()}
          borderRadius="md"
          boxShadow="none"
          _hover={{
            bgColor: uiColors.white(),
          }}
          _focus={{
            bgColor: uiColors.white(),
          }}
          aria-label="portfolio recommendation"
        >
          <Flex gap="2" alignItems="center">
            {triggerText}

            {triggerIcon}
          </Flex>
        </Button>
      </PopoverTrigger>

      <PopoverContent boxShadow={uiConstants.boxShadow} {...popoverContentProps}>
        <PopoverArrow />

        <PopoverBody {...popoverBodyProps}>{popoverBody}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default PopoverTooltip;
