import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Camelized, decamelizeKeys } from 'humps';

import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';
import { RecurringOrderPostRequestBody } from '~/types';

import { qkRecurringOrders } from './useGetRecurringOrders';

const usePostRecurringOrder = () => {
  const { apiClient } = useAxiosContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: Camelized<RecurringOrderPostRequestBody>) => {
      return apiClient?.post(apiRoutes.recurringOrders(), decamelizeKeys(body));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [qkRecurringOrders] });
    },
  });
};

export default usePostRecurringOrder;
