'use client';

import { CheckboxGroup, Flex, FormControl, FormErrorMessage, FormLabel, useCheckboxGroup } from '@chakra-ui/react';
import { CfCheckbox } from '@cryptofi/core-ui';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { RoboRiskToleranceQuestion } from '~/types';

type Props = {
  question: RoboRiskToleranceQuestion;
  register: UseFormRegister<{ [key: string]: string[] }>;
  errorMessage?: string;
  setValue: UseFormSetValue<{ [key: string]: string[] }>;
};

export const RiskToleranceMultiSelect = ({ question, register, errorMessage, setValue }: Props) => {
  const { value, getCheckboxProps } = useCheckboxGroup({
    defaultValue: [],
  });

  // work around for possible undefined id
  if (!question.id) {
    console.error('question.id is undefined');
    return null;
  }

  const handleChange = (value: Array<string>): void => {
    setValue(question.id!, value, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  };

  const registerProps = register(question.id, {
    required: 'Please select at least one option',
  });

  return (
    <FormControl key={question.id} isInvalid={Boolean(errorMessage)} isRequired>
      <FormLabel>{question.question}</FormLabel>

      <CheckboxGroup value={value} {...registerProps} onChange={handleChange}>
        {question?.responses?.map((option, index) => (
          <Flex key={`${index}-${question.id}`} my="4" alignItems="center">
            <CfCheckbox
              name={question.id!}
              size="md"
              width="auto"
              label={option.response}
              labelPosition="right"
              isInvalid={Boolean(errorMessage)}
              {...getCheckboxProps({ value: option.response })}
            />
          </Flex>
        ))}
      </CheckboxGroup>

      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};
