'use client';

import { Box, Flex, Grid, GridItem, Skeleton, Text } from '@chakra-ui/react';
import { dateFormats, IconMinus, IconMore, IconPlus, uiColors, utcDateToLocal } from '@cryptofi/core-ui';
import { range } from 'lodash';

import { RoboTransaction } from '~/types';

interface LatestRoboTransactionsProps {
  transactions: RoboTransaction[];
  onClick?: () => void;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
}

//todo: consider adding an <ErrorBoundary /> here and in LatestTransactions

export default function LatestRoboTransactions({
  transactions,
  onClick,
  isLoading = false,
  isSuccess = false,
  isError = false,
}: LatestRoboTransactionsProps) {
  const transactionCount = transactions?.length;
  const isTwoRows = transactionCount > 3;
  const borderStyle = '1px solid var(--chakra-colors-gray-200)';

  const getBorderStyles = (index: number) => {
    if (transactionCount <= 1) {
      return {};
    }

    if (isTwoRows) {
      const isFirstRow = index < 2;
      const isFirstInRow = index % 2 === 0;

      return {
        borderRight: {
          base: 'none',
          md: isFirstInRow ? borderStyle : 'none', // First item in each row gets right border
        },
        borderBottom: {
          base: 'none',
          md: isFirstRow ? borderStyle : 'none', // First row gets bottom border
        },
      };
    }

    // Single row layout
    return {
      borderRight: {
        base: 'none',
        // eslint-disable-next-line no-restricted-syntax
        md: index < transactionCount - 1 ? borderStyle : 'none',
      },
    };
  };

  const renderIcon = (transactionType: string) => {
    const sharedStyles = { borderRadius: '50%', padding: '4px', width: '1.3rem', height: '1.3rem' };

    if (transactionType === 'REBALANCE') {
      return (
        <IconMore
          __css={{ path: { fill: 'rgba(240, 190, 0, 1)' }, backgroundColor: 'rgba(255, 250, 221, 1)', ...sharedStyles }}
        />
      );
    }
    if (transactionType === 'DEPOSIT') {
      return (
        <Box borderRadius="100" bgColor={uiColors.aijiroWhite()} p="1">
          <IconPlus h="4" w="4" __css={{ path: { fill: uiColors.mint() } }} />
        </Box>
      );
    }
    if (transactionType === 'WITHDRAWAL') {
      return (
        <Box borderRadius="100" bgColor={uiColors.redRemains()} p="1">
          <IconMinus h="3" w="3" __css={{ path: { fill: uiColors.heroicRed() } }} />
        </Box>
      );
    }
  };

  return (
    <>
      <Text as="h2" fontSize="lg" fontWeight="bold" mb="6">
        Latest transactions
      </Text>

      <Grid
        templateColumns={{
          base: '1fr',
          sm: isTwoRows ? 'repeat(2, 1fr)' : '1fr',
          md: `repeat(${isTwoRows ? 2 : transactionCount}, 1fr)`,
        }}
        templateRows={isTwoRows ? 'repeat(2, 1fr)' : '1fr'}
        onClick={onClick}
        cursor="pointer"
      >
        {isLoading &&
          range(4).map((_, i) => (
            <GridItem key={i} h={8}>
              <Skeleton h="full" />
            </GridItem>
          ))}

        {isSuccess && !transactionCount && (
          <GridItem h="full" textAlign="center" p="4" colSpan={2} alignSelf="center" bgColor={uiColors.lighthouse()}>
            Make a deposit or withdrawal to see your latest transactions
          </GridItem>
        )}

        {isError && (
          <GridItem h="full" textAlign="center" p="4" colSpan="auto" alignSelf="center" bgColor={uiColors.lighthouse()}>
            There was an error loading your transactions. Please try again later.
          </GridItem>
        )}

        {isSuccess &&
          transactions.slice(0, 4).map((transaction, index) => (
            <Flex
              key={transaction.sKey}
              justifyContent="space-between"
              py={{ base: '2', md: '4' }}
              px="6"
              {...getBorderStyles(index)}
            >
              <Flex alignItems="center" gap="2">
                {renderIcon(transaction.roboTransactionType)}

                <Text>
                  {transaction.roboTransactionType
                    .charAt(0)
                    .toUpperCase()
                    .concat(transaction.roboTransactionType.slice(1).toLowerCase())}
                </Text>
              </Flex>

              <Flex flexDirection="column" alignItems="flex-end">
                <Text fontWeight="bold">${transaction.roboTransactionAmount}</Text>

                <Text>{utcDateToLocal({ dateFormat: dateFormats.dateOnly, timeString: transaction.dateAdded })}</Text>
              </Flex>
            </Flex>
          ))}
      </Grid>
    </>
  );
}
