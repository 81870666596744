'use client';

import { Flex, Show, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { uiColors } from '@cryptofi/core-ui';
import { useEffect, useMemo, useState } from 'react';

import { InvestmentCard, InvestmentsTable, SearchInput, SecurityFilters } from '~/components';
import { useFilter } from '~/hooks';
import { AssetType, Investment, InvestmentFilter, TableColumn } from '~/types';

type Props = {
  showFilters?: boolean;
  assets: Investment[];
  assetFilterOptions: InvestmentFilter[];
  columns: TableColumn<Investment>[];
  assetType: AssetType;
};

const EmptyState = () => (
  <Flex w="full" flexDir="column" gap="6">
    <Table>
      <Tbody>
        <Tr>
          <Td colSpan={6} textAlign="center" py="12" px="6" color={uiColors.sonicSilver()}>
            No matching results found.
          </Td>
        </Tr>
      </Tbody>
    </Table>
  </Flex>
);

const InvestmentsSection = ({ showFilters = true, assets, assetFilterOptions = [], columns, assetType }: Props) => {
  const { activeFilter, setActiveFilter, applyFilter } = useFilter(assetFilterOptions);
  const [searchTerm, setSearchTerm] = useState('');

  const [debouncedTerm, setDebouncedTerm] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  const filteredInvestments = useMemo(() => {
    const filtered = showFilters ? applyFilter(assets) : assets;

    return filtered
      .filter((asset) => {
        if (!debouncedTerm) {
          return true;
        }

        return (
          asset.assetId.toLowerCase().includes(debouncedTerm.toLowerCase()) ||
          asset.assetName?.toLowerCase().includes(debouncedTerm.toLowerCase())
        );
      })
      .map((asset) => ({
        ...asset,
        amountUsd: asset.amountUsd ?? 0,
      }));
  }, [assets, showFilters, applyFilter, debouncedTerm]);

  return (
    <Flex flexDir="column" gap="6">
      <Flex width="100%" flexDir={{ base: 'column', md: 'row' }} gap="4" justifyContent="space-between">
        {showFilters && (
          <SecurityFilters options={assetFilterOptions} onFilterChange={setActiveFilter} initialFilter={activeFilter} />
        )}

        <SearchInput
          searchTerm={searchTerm}
          onChange={setSearchTerm}
          name="asset-search"
          ariaLabel="Search your assets"
        />
      </Flex>

      {filteredInvestments.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          <Show above="lg">
            <InvestmentsTable investments={filteredInvestments} columns={columns} assetType={assetType} />
          </Show>

          <Show below="lg">
            <Flex flexDir="column" gap="2">
              {filteredInvestments.map((asset) => (
                <InvestmentCard key={asset.assetId} investment={asset} variant="detail" />
              ))}
            </Flex>
          </Show>
        </>
      )}
    </Flex>
  );
};

export default InvestmentsSection;
