'use client';

import {
  Button,
  Flex,
  Grid,
  GridItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { getOrdinalSuffix, IconMore, IconRefresh, uiColors } from '@cryptofi/core-ui';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

// import { useDeleteRecurringOrder } from '~/hooks';
import { uiConstants } from '~/theme';
import { RecurringOrder } from '~/types';

type RecurringOrderProps = { recurringOrders: RecurringOrder[]; handleAddRecurringOrder: () => void };

const recurringOrderIntervalLookup = (recurringOrder: RecurringOrder) => {
  switch (recurringOrder.interval) {
    case 'WEEKLY':
      return `Weekly on ${capitalize(recurringOrder.weeklyDayOfWeek as string)}`;
    case 'BIWEEKLY':
      return `Biweekly on ${capitalize(recurringOrder.weeklyDayOfWeek as string)}`;
    case 'MONTHLY':
      return Boolean(recurringOrder?.monthlyDayOfMonth)
        ? `Monthly on the ${recurringOrder.monthlyDayOfMonth}${getOrdinalSuffix(recurringOrder.monthlyDayOfMonth || 0)}`
        : 'Monthly';
  }
};

const dayToNumber: Record<string, number> = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

const getNextOrderDate = (recurringOrder: RecurringOrder) => {
  const today = dayjs();

  switch (recurringOrder.interval) {
    case 'WEEKLY': {
      const targetDay = recurringOrder.weeklyDayOfWeek?.toLowerCase();
      let nextDate = today.day(dayToNumber[targetDay || 'sunday']);
      if (nextDate.isBefore(today)) {
        nextDate = nextDate.add(1, 'week');
      }
      return nextDate;
    }

    case 'BIWEEKLY': {
      const targetDay = recurringOrder.weeklyDayOfWeek?.toLowerCase();
      let nextDate = today.day(dayToNumber[targetDay || 'sunday']);
      if (nextDate.isBefore(today)) {
        nextDate = nextDate.add(1, 'week');
      }
      // Add another week if we're in the wrong parity
      const isOddWeek = nextDate.week() % 2 === 1;
      if (
        (recurringOrder.biweeklyParity === 'ODD' && !isOddWeek) ||
        (recurringOrder.biweeklyParity === 'EVEN' && isOddWeek)
      ) {
        nextDate = nextDate.add(1, 'week');
      }
      return nextDate;
    }

    case 'MONTHLY': {
      const targetDay = recurringOrder.monthlyDayOfMonth || 1;
      let nextDate = today.date(targetDay);
      if (nextDate.isBefore(today)) {
        nextDate = nextDate.add(1, 'month');
      }
      return nextDate;
    }

    default:
      return today;
  }
};

const RecurringOrders = ({ recurringOrders, handleAddRecurringOrder }: RecurringOrderProps) => {
  // const deleteOrder = useDeleteRecurringOrder();

  // const handleDelete = (recurringOrder: RecurringOrder) => {
  //   if (recurringOrder.systemId) {
  //     deleteOrder.mutate(recurringOrder.systemId);
  //   }
  // };

  return (
    <Flex flexDirection="column" width="full">
      {recurringOrders.length > 0 ? (
        <>
          <Text as="h2" fontSize="lg" fontWeight="bold" mb="6">
            Recurring orders
          </Text>

          <Grid width="full" overflowX="auto" gridTemplateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gridGap="2">
            {recurringOrders.slice(0, recurringOrders.length > 4 ? 4 : recurringOrders.length).map((recurringOrder) => (
              <GridItem key={recurringOrder.systemId} border={`1px solid ${uiColors.lighthouse()}`} rounded="md" mx="2">
                <Flex
                  borderBottom={`1px solid ${uiColors.lighthouse()}`}
                  justifyContent="space-between"
                  alignItems="center"
                  whiteSpace="nowrap"
                  p="4"
                >
                  <Flex gap="4">
                    <IconRefresh
                      __css={{
                        path: { fill: uiColors.sonicSilver() },
                        height: '2rem',
                        width: '2rem',
                        backgroundColor: uiColors.lighthouse(),
                        borderRadius: '10px',
                        padding: '8px',
                      }}
                    />

                    <Text fontWeight="bold" alignContent="center">
                      {recurringOrderIntervalLookup(recurringOrder)}
                    </Text>
                  </Flex>

                  <Popover>
                    <PopoverTrigger>
                      <Button variant="ghost">
                        <IconMore />
                      </Button>
                    </PopoverTrigger>

                    <PopoverContent boxShadow={uiConstants.boxShadow} width="7rem" height="3rem">
                      <PopoverBody p="1">
                        <Button
                          variant="ghost"
                          width="full"
                          // TODO delete is returning a 500, but leaving the UI in place for demo purposes
                          // onClick={() => handleDelete(recurringOrder)}
                          // * this isn't rendering unfortunately
                          // leftIcon={
                          //   <IconTrash
                          //     __css={{
                          //       path: { fill: `${uiColors.heroicRed()} !important` },
                          //       height: '1rem',
                          //       width: '1rem',
                          //     }}
                          //     _hover={{ backgroundColor: 'transparent' }}
                          //   />
                          // }
                          border={`1px solid ${uiColors.heroicRed()}`}
                          color={uiColors.heroicRed()}
                          fontSize="sm"
                          _hover={{ backgroundColor: 'transparent' }}
                          transition="none"
                        >
                          Delete
                        </Button>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Flex>

                <Flex gap="4" p="4" backgroundColor={uiColors.lighthouse()}>
                  <Flex flexDirection="column" gap="2">
                    <Text color={uiColors.sonicSilver()}>Amount</Text>

                    <Text fontWeight="bold">${recurringOrder.orderPrice}</Text>
                  </Flex>

                  <Flex flexDirection="column" gap="2">
                    <Text color={uiColors.sonicSilver()}>Start Date</Text>

                    <Text fontWeight="bold">{dayjs(recurringOrder.dateAdded).add(1, 'day').format('MM/DD/YYYY')}</Text>
                  </Flex>

                  <Flex flexDirection="column" gap="2">
                    <Text color={uiColors.sonicSilver()}>Next Order</Text>

                    <Text fontWeight="bold">{getNextOrderDate(recurringOrder).format('MM/DD/YYYY')}</Text>
                  </Flex>
                </Flex>
              </GridItem>
            ))}
          </Grid>
        </>
      ) : (
        <Button variant="secondary" alignSelf="center" width="14rem" fontSize="sm" onClick={handleAddRecurringOrder}>
          Add recurring investment
        </Button>
      )}
    </Flex>
  );
};

export default RecurringOrders;
