'use client';

import { Link } from '@chakra-ui/next-js';
import { Flex, Table, TableContainer, Tbody, Td as ChakraTd, Th as ChakraTh, Thead, Tr } from '@chakra-ui/react';
import { CfCard, CfSpinner, dateFormats, formatUsd, IconPieChart, utcDateToLocal } from '@cryptofi/core-ui';
import { UseQueryResult } from '@tanstack/react-query';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { size } from 'lodash';
import { Fragment } from 'react';

import { AssetLogo, TransactionStatus } from '~/components';
import { uiRoutes } from '~/routes';
import { Transaction, TransactionPaginator } from '~/types';
import { remToPixels } from '~/utils';

interface TransactionHistoryTableProps {
  transactions: UseQueryResult<TransactionPaginator | undefined, Error>;
}

const columnHelper = createColumnHelper<Transaction>();

const Th = ({ children, ...props }: React.ComponentProps<typeof ChakraTh>) => (
  <ChakraTh py="3" px="4" {...props}>
    {children}
  </ChakraTh>
);

const Td = ({ children, ...props }: React.ComponentProps<typeof ChakraTd>) => (
  <ChakraTd py="3" px="4" fontFamily="mono" {...props}>
    {children}
  </ChakraTd>
);

const columns = [
  columnHelper.accessor('asset', {
    header: () => <Th>Asset</Th>,
    cell: (info) => {
      const transaction = info.row.original;
      const isDividend = transaction.transactionType === 'dividend';
      const isCrypto = transaction.assetType === 'CRYPTO';

      if (isDividend) {
        return (
          <Td>
            <Flex alignItems="center" gap={4}>
              {transaction.asset && <IconPieChart />}
              USD
            </Flex>
          </Td>
        );
      }

      return (
        <Td>
          <Link
            display="inline-block"
            href={
              isCrypto
                ? uiRoutes.assetDetailsCrypto(transaction.asset || '')
                : uiRoutes.assetDetailsSecurities(transaction.asset || '')
            }
          >
            <Flex alignItems="center" gap={4}>
              {transaction.asset && (
                <AssetLogo
                  assetId={transaction.asset}
                  assetType={transaction.assetType === 'CRYPTO' ? 'crypto' : 'securities'}
                />
              )}

              {transaction.asset}
            </Flex>
          </Link>
        </Td>
      );
    },
  }),
  columnHelper.accessor('orderTxId', {
    header: () => <Th textAlign="right">ID</Th>,
    cell: (info) => <Td textAlign="right">{info.getValue() || '-'}</Td>,
    size: remToPixels(8),
  }),
  columnHelper.accessor('transactTime', {
    header: () => <Th textAlign="right">Date</Th>,
    cell: (info) => (
      <Td textAlign="right">{utcDateToLocal({ dateFormat: dateFormats.dateOnly, timeString: info.getValue() })}</Td>
    ),
  }),
  columnHelper.accessor('transactionName', {
    header: () => <Th textAlign="center">Type</Th>,
    cell: (info) => <Td textAlign="center">{info.getValue()}</Td>,
    size: remToPixels(8),
  }),
  columnHelper.accessor('assetAmount', {
    header: () => <Th textAlign="right">Amount</Th>,
    cell: (info) => {
      const isDividend = info.row.original.transactionType === 'dividend';
      const value = isDividend
        ? formatUsd({ amount: info.getValue() || 0 })
        : info.getValue()?.toFixed(info.row.original.assetType === 'CRYPTO' ? 8 : 5);
      return <Td textAlign="right">{value}</Td>;
    },
  }),
  columnHelper.accessor('assetPrice', {
    header: () => <Th textAlign="right">Price</Th>,
    cell: (info) => <Td textAlign="right">{formatUsd({ amount: info.getValue() || 0 })}</Td>,
  }),
  columnHelper.accessor('usdAmountGross', {
    header: () => <Th textAlign="right">Cost</Th>,
    cell: (info) => <Td textAlign="right">{formatUsd({ amount: info.getValue() || 0 })}</Td>,
  }),
  columnHelper.accessor('feeAmount', {
    header: () => <Th textAlign="right">Fee</Th>,
    cell: (info) => <Td textAlign="right">{formatUsd({ amount: info.getValue() || 0 })}</Td>,
  }),
  columnHelper.accessor('status', {
    header: () => <Th textAlign="center">Status</Th>,
    cell: (info) => (
      <Td textAlign="center" justifyItems="center">
        <TransactionStatus transaction={info.row.original} />
      </Td>
    ),
  }),
];

const TransactionHistoryTable = ({ transactions }: TransactionHistoryTableProps) => {
  const { data, isLoading } = transactions;
  const hasData = Boolean(size(data?.items));

  const table = useReactTable<Transaction>({
    data: data?.items || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (isLoading) {
    return (
      <CfCard>
        <CfSpinner />
      </CfCard>
    );
  }

  return (
    <Flex direction="column" gap={4} w="full">
      <CfCard align="center">
        <TableContainer w="full">
          {hasData && (
            <Table variant="simple" fontSize="sm">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Fragment key={header.id}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Fragment>
                    ))}
                  </Tr>
                ))}
              </Thead>

              <Tbody>
                {!hasData ? (
                  <Tr>
                    <Td textAlign="center" colSpan={100}>
                      No transactions found
                    </Td>
                  </Tr>
                ) : (
                  table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <Fragment key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Fragment>
                      ))}
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </CfCard>
    </Flex>
  );
};

export default TransactionHistoryTable;
