'use client';

import { CfSelect } from '@cryptofi/core-ui';
import { UseFormRegister } from 'react-hook-form';

import { RoboRiskToleranceQuestion } from '~/types';

type Props = {
  question: RoboRiskToleranceQuestion;
  register: UseFormRegister<{ [key: string]: string }>;
  errorMessage?: string;
};

export const RiskToleranceSelectInput = ({ question, register, errorMessage }: Props) => {
  // work around for possible undefined id
  if (!question.id) {
    console.error('question.id is undefined');
    return null;
  }

  return (
    <CfSelect
      key={question.id}
      name={question.id}
      label={question.question}
      register={() =>
        register(question.id!, {
          required: 'Please select an option',
        })
      }
      errorMessage={errorMessage}
      defaultValue={''}
      isRequired
    >
      <option disabled value="">
        Select...
      </option>

      {question.responses?.map((option) => (
        <option key={`${question.id}-${option.riskValue}`} value={option.response}>
          {option.response}
        </option>
      ))}
    </CfSelect>
  );
};
