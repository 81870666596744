'use client';

import { Box, Flex, Heading, IconButton, ModalProps, Text } from '@chakra-ui/react';
import { CfModal, IconCaretLeft, uiColors } from '@cryptofi/core-ui';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FormProvider } from 'react-hook-form';

import { InvestAssetsSearch } from '~/components';
import { useGetTokenPrices, useGetUserBankAccounts, useInvestModalContext } from '~/hooks';
import { logError } from '~/utils';

import ReviewTransaction from './ReviewTransaction';
import SelectCryptoAsset from './SelectCryptoAsset';
import StartTransaction from './StartTransaction';
import TransactionResults from './TransactionResults';

const InvestModal = ({ onClose, isOpen, ...rest }: Omit<ModalProps, 'children'>) => {
  const {
    modalView,
    setModalView,
    setTransactionType,
    defaultAssetId,
    defaultAssetType,
    selectedAsset,
    setSelectedAsset,
    selectedSecurity,
    transactionState,
    formMethods,

    setBuyCurrency,
    setBuyPrice,
    setNetBuyQuantity,

    setSellCurrency,
    setSellPrice,
  } = useInvestModalContext();

  const tokenPrices = useGetTokenPrices();
  const bankAccounts = useGetUserBankAccounts();

  useEffect(() => {
    if (selectedAsset?.type === 'securities') {
      selectedSecurity?.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset]);

  // lock in the current price when the modal is opened
  useEffect(
    () => {
      if (isOpen) {
        setBuyPrice(
          selectedAsset?.type === 'crypto'
            ? Number(tokenPrices?.data?.find((t) => t?.baseToken === selectedAsset.id)?.buyPrice) || 0
            : Number(selectedSecurity?.data?.currentPrice?.value) || 0,
        );
        setSellPrice(
          selectedAsset?.type === 'crypto'
            ? Number(tokenPrices?.data?.find((t) => t?.baseToken === selectedAsset.id)?.sellPrice) || 0
            : Number(selectedSecurity?.data?.currentPrice?.value) || 0,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen, selectedAsset, selectedSecurity?.data?.currentPrice?.value],
  );

  // refetch bank accounts when the modal is opened
  // temp fix for user trying to complete a transaction after KYC, but before bank accounts are loaded via refetch interval
  // TODO when can this be removed?
  useEffect(
    () => {
      bankAccounts.refetch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen],
  );

  const views = {
    startTransaction: {
      headerText: '',
      headerContent: bankAccounts.isSuccess && <StartTransaction.HeaderContent />,
      bodyContent: <StartTransaction isLoading={bankAccounts.isLoading} />,
      footerContent: bankAccounts.isSuccess && <StartTransaction.FooterContent />,
    },
    searchAssets: {
      headerText: 'Search',
      headerContent: null,
      bodyContent: <InvestAssetsSearch />,
      footerContent: null,
    },
    selectAsset: {
      headerText: 'Select asset',
      headerContent: null,
      bodyContent: <SelectCryptoAsset tokenPrices={tokenPrices?.data || []} />,
      footerContent: null,
    },
    reviewTransaction: {
      headerText: 'Preview order',
      headerContent: <ReviewTransaction.HeaderContent />,
      bodyContent: <ReviewTransaction />,
      footerContent: <ReviewTransaction.FooterContent />,
    },
    transactionResults: {
      headerText: transactionState?.isSuccess ? 'Order placed!' : 'Something went wrong',
      headerContent: null,
      bodyContent: <TransactionResults />,
      footerContent: transactionState?.isError ? <TransactionResults.FooterContent /> : null,
    },
  };

  const headerContent = (
    <>
      {(modalView === 'selectAsset' || modalView === 'reviewTransaction' || modalView === 'searchAssets') && (
        <IconButton
          isDisabled={transactionState?.isPending}
          icon={<IconCaretLeft __css={{ path: { fill: 'black !important' } }} />}
          position="absolute"
          left="3"
          top="2"
          variant="ghost"
          aria-label="Back to order entry"
          size="sm"
          onClick={() => {
            setModalView('startTransaction');
          }}
          _hover={{ bg: 'blackAlpha.100' }}
          _focus={{ bg: 'blackAlpha.100' }}
        />
      )}

      <Heading as="h1" fontSize="xl" textAlign="center" maxWidth="85%" marginX="auto">
        {views[modalView].headerText}
      </Heading>

      {views[modalView].headerContent}
    </>
  );

  if (!selectedAsset) {
    return null;
  }

  return (
    <FormProvider {...formMethods}>
      <CfModal
        closeOnEsc={!transactionState?.isPending}
        closeOnOverlayClick={!transactionState?.isPending}
        onClose={onClose}
        onCloseComplete={() => {
          // reset state
          setTransactionType('buy');
          setModalView('startTransaction');
          setSelectedAsset({ id: defaultAssetId!, type: defaultAssetType! });
          setBuyCurrency('USD');
          setNetBuyQuantity(0);
          setSellCurrency('USD');
          setBuyPrice(0);
          setSellPrice(0);

          // reset the form
          formMethods.reset();
        }}
        isDisabledCloseButton={transactionState?.isPending}
        isOpen={isOpen}
        headerContent={headerContent}
        footerContent={views[modalView].footerContent}
        {...rest}
      >
        <Box className="error-boundary" width="full">
          <ErrorBoundary
            onError={(error) => {
              logError({ error });
            }}
            fallback={
              isOpen ? (
                <Flex>
                  <Text fontSize="md" color={uiColors.heroicRed()}>
                    Error: There was an unexpected error. Please try again.
                  </Text>
                </Flex>
              ) : null
            }
          >
            {views[modalView].bodyContent}
          </ErrorBoundary>
        </Box>
      </CfModal>
    </FormProvider>
  );
};

export default InvestModal;
