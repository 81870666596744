import { useQuery } from '@tanstack/react-query';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { defaultRecordsPerPage } from '~/constants';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { RoboTransactionPaginator } from '~/types';
import { ignoreUpperCaseKeys } from '~/utils';

interface GetRoboTransactionsProps {
  params?: Record<string, any>;
  recordsPerPage?: number;
  enabled?: boolean;
}

export const qkRoboTransactions = 'qk:roboTransactions';

const useGetRoboTransactions = ({
  params,
  recordsPerPage = defaultRecordsPerPage,
  enabled = false,
}: GetRoboTransactionsProps) => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();

  return useQuery({
    enabled: Boolean(userAuthInfo) && enabled,
    queryKey: [qkRoboTransactions, params, recordsPerPage],
    queryFn: async () => {
      return apiClient
        ?.get(apiRoutes.roboTransactions(), {
          params: decamelizeKeys({
            scanIndexForward: false, // This sorts in descending order
            perPage: recordsPerPage,
            ...params,
          }),
        })
        .then((r) => camelizeKeys(r.data, { process: ignoreUpperCaseKeys }) as RoboTransactionPaginator);
    },
  });
};

export default useGetRoboTransactions;
