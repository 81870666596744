import { yupResolver } from '@hookform/resolvers/yup';
import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  roboTransactionFormSchema,
  RoboTransactionFormValues,
} from '~/components/RoboInvestModal/roboTransactionSchema';
import { Maybe, RoboTransactionTypes } from '~/types';

type RoboTransactionModalViews = 'depositForm' | 'withdrawalForm' | 'transactionResults';

type RecurringOrderCadence = 'WEEKLY' | 'BIWEEKLY' | 'MONTHLY';

type Context = {
  modalView: RoboTransactionModalViews;
  setModalView: Dispatch<SetStateAction<RoboTransactionModalViews>>;
  transactionType: RoboTransactionTypes;
  setTransactionType: Dispatch<SetStateAction<RoboTransactionTypes>>;
  //todo: remove transactionState and instead rely on query hook state
  transactionState?: { isPending: boolean; isError: boolean; isSuccess: boolean };
  setTransactionState: Dispatch<SetStateAction<{ isPending: boolean; isError: boolean; isSuccess: boolean }>>;
  recurringOrderCadence: Maybe<RecurringOrderCadence>;
  setRecurringOrderCadence: Dispatch<SetStateAction<Maybe<RecurringOrderCadence>>>;
  roboDepositAmount: number;
  setRoboDepositAmount: Dispatch<SetStateAction<number>>;
  roboWithdrawalAmount: number;
  setRoboWithdrawalAmount: Dispatch<SetStateAction<number>>;
  isMaxWithdrawal: boolean;
  setIsMaxWithdrawal: Dispatch<SetStateAction<boolean>>;
  formMethods: ReturnType<typeof useForm<RoboTransactionFormValues>>;
};

const RoboInvestModalContext = createContext({} as Context);

const RoboInvestModalProvider = ({ children }: { children: ReactNode }) => {
  const [modalView, setModalView] = useState<RoboTransactionModalViews>('depositForm');
  const [transactionType, setTransactionType] = useState<RoboTransactionTypes>('deposit');
  const [roboDepositAmount, setRoboDepositAmount] = useState<number>(0);
  const [roboWithdrawalAmount, setRoboWithdrawalAmount] = useState<number>(0);
  const [recurringOrderCadence, setRecurringOrderCadence] = useState<Maybe<RecurringOrderCadence>>(null);
  const [isMaxWithdrawal, setIsMaxWithdrawal] = useState<boolean>(false);
  const [transactionState, setTransactionState] = useState<{
    isPending: boolean;
    isError: boolean;
    isSuccess: boolean;
  }>({ isPending: false, isError: false, isSuccess: false });

  const formMethods = useForm<RoboTransactionFormValues>({
    resolver: yupResolver(roboTransactionFormSchema()),
    mode: 'onChange',
  });

  return (
    <RoboInvestModalContext.Provider
      value={{
        formMethods,
        isMaxWithdrawal,
        setIsMaxWithdrawal,
        modalView,
        setModalView,
        roboDepositAmount,
        setRoboDepositAmount,
        roboWithdrawalAmount,
        setRoboWithdrawalAmount,
        recurringOrderCadence,
        setRecurringOrderCadence,
        transactionState,
        setTransactionState,
        transactionType,
        setTransactionType,
      }}
    >
      {children}
    </RoboInvestModalContext.Provider>
  );
};

export { RoboInvestModalContext, RoboInvestModalProvider };
