'use client';

import { Link } from '@chakra-ui/next-js';
import { Text } from '@chakra-ui/react';
import { CfCheckbox, CfCheckboxProps, uiColors } from '@cryptofi/core-ui';
import { Fragment } from 'react';

import { PolicyLink } from '~/types';

interface TermsCheckboxProps extends CfCheckboxProps {
  description?: string | null;
  isArbitrationClause?: boolean;
  policies?: Array<PolicyLink | null | undefined> | null;
  termsParty: string;
}

const getSeparator = ({ index, length }: { index: number; length: number }) => {
  // don't render separator for last item
  // eslint-disable-next-line no-restricted-syntax
  if (index === length - 1) {
    return '';
  }

  // if theres only 2, this is the only separator
  // if theres more than 2, its the last separator
  // eslint-disable-next-line no-restricted-syntax
  if ((index === 0 && length === 2) || (length > 2 && index === length - 2)) {
    return ' and ';
  }

  // this only leaves commas, which will show when theres more than 2
  if (length > 2) {
    return ', ';
  }
};

export default function TermsCheckbox({
  description,
  isArbitrationClause,
  policies,
  termsParty,
  ...props
}: TermsCheckboxProps) {
  if (!policies) {
    return null;
  }

  const getText = ({ label }: { label: string }) => {
    // omit "the" before privacy policy link
    if (label.toLowerCase() === 'privacy policy') {
      return null;
    }

    // return "the party name"
    if (termsParty) {
      return `the ${termsParty}`;
    }

    return 'the';
  };

  const policyLinks = policies
    .map((policy, index) => {
      if (!policy) {
        return null;
      }
      {
        /* eslint-disable react/jsx-newline */
      }
      return (
        <Fragment key={index}>
          {' '}
          {getText({ label: policy.label })}{' '}
          <Link textDecor="underline" key={policy.label} href={policy.url} target={policy.url}>
            {policy.label}
          </Link>
          {isArbitrationClause && ' arbitration clause'}
          {policies && getSeparator({ index, length: policies.length })}
        </Fragment>
      );
      {
        /* eslint-enable react/jsx-newline */
      }
    })
    .filter((link) => link !== null);

  if (!policyLinks.length) {
    return null;
  }

  const label = (
    <>
      <Text fontSize="xs" color={uiColors.black()}>
        I agree to {policyLinks}
      </Text>

      {description && (
        <Text lineHeight="1.25" fontSize="2xs">
          {description}
        </Text>
      )}
    </>
  );

  return (
    <CfCheckbox
      flexDir="column"
      pt="2px"
      pl="2px"
      verticalAlign="top"
      label={label}
      labelPosition="right"
      size="md"
      {...props}
    />
  );
}
