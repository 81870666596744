import { Button, Flex } from '@chakra-ui/react';
import { memo } from 'react';

import { InvestmentFilter } from '~/types';

type FilterProps = {
  options: InvestmentFilter[];
  onFilterChange: (filter: string) => void;
  initialFilter?: string;
};

const SecurityFilters = memo(({ options, onFilterChange, initialFilter }: FilterProps) => {
  return (
    <Flex alignItems="center" gap="2" flexWrap={{ base: 'wrap', md: 'nowrap' }} w={{ base: '100%', md: 'auto' }}>
      {options.map(({ id, label }) => (
        <Button
          key={id}
          variant={initialFilter === id ? 'solid' : 'outline'}
          onClick={() => onFilterChange(id)}
          size="sm"
          borderRadius="full"
          bg={initialFilter === id ? 'black' : 'white'}
          color={initialFilter === id ? 'white' : 'black'}
          borderColor={initialFilter === id ? 'black' : 'gray.200'}
          boxShadow="none"
          borderWidth="1px"
          flex={{ base: '1', md: '0 1 auto' }}
          minWidth={{ base: 'auto', md: '100px' }}
          _active={{
            bg: 'black',
            color: 'white',
          }}
          _focus={{
            bg: 'black',
            color: 'white',
          }}
        >
          {label}
        </Button>
      ))}
    </Flex>
  );
});

SecurityFilters.displayName = 'SecurityFilters';

export default SecurityFilters;
