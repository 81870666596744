import { useForm } from 'react-hook-form';

import { usePostSecuritiesRiskTolerance } from '~/hooks';
import { RiskToleranceLookup } from '~/types';

interface RiskToleranceFormValues {
  riskTolerance: RiskToleranceLookup;
}

const useRiskToleranceForm = (onSuccess: () => void) => {
  const postRiskTolerance = usePostSecuritiesRiskTolerance();

  const form = useForm<RiskToleranceFormValues>({
    mode: 'onSubmit',
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;
  const isLoading = isSubmitting || postRiskTolerance.isPending;

  const submitHandler = (values: RiskToleranceFormValues) => {
    if (!values.riskTolerance) {
      return;
    }

    postRiskTolerance.mutate(values.riskTolerance, {
      onSuccess,
    });
  };

  const onSubmit = () => {
    handleSubmit(submitHandler)();
  };

  return {
    form,
    isLoading,
    onSubmit,
  };
};

export default useRiskToleranceForm;
