'use client';

import {
  Collapse,
  Flex,
  Grid,
  Table,
  TableContainer,
  Tbody,
  Td as ChakraTd,
  Th as ChakraTh,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { CfCard, CfSpinner, CfTag, dateFormats, formatUsd, IconCaretDown, utcDateToLocal } from '@cryptofi/core-ui';
import { UseQueryResult } from '@tanstack/react-query';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { rest, size } from 'lodash';
import { Fragment } from 'react';

import { TransactionStatus } from '~/components';
import { OrderStatusKeys, orderStatusLookup } from '~/constants';
import { RoboTransaction } from '~/types';
import { remToPixels } from '~/utils';

interface RoboTransactionHistoryTableProps {
  transactions: UseQueryResult<any | undefined, Error>;
}

const columnHelper = createColumnHelper<RoboTransaction>();

const Th = ({ children, ...props }: React.ComponentProps<typeof ChakraTh>) => (
  <ChakraTh py="3" px="4" {...props}>
    {children}
  </ChakraTh>
);

const Td = ({ children, ...props }: React.ComponentProps<typeof ChakraTd>) => (
  <ChakraTd py="3" px="4" fontFamily="mono" {...props}>
    {children}
  </ChakraTd>
);

const columns = [
  columnHelper.accessor('roboTransactionType', {
    header: () => <Th textAlign="left">Type</Th>,
    cell: (info) => <Td textAlign="left">{info.getValue()}</Td>,
    size: remToPixels(8),
  }),

  columnHelper.accessor('dateAdded', {
    header: () => <Th textAlign="right">Date</Th>,
    cell: (info) => (
      <Td textAlign="right">{utcDateToLocal({ dateFormat: dateFormats.dateOnly, timeString: info.getValue() })}</Td>
    ),
  }),

  columnHelper.accessor('roboTransactionAmount', {
    header: () => <Th textAlign="right">Total USD</Th>,
    cell: (info) => <Td textAlign="right">{formatUsd({ amount: info.getValue() || 0 })}</Td>,
  }),
  columnHelper.accessor('transactions', {
    header: () => <Th textAlign="center">Status</Th>,
    cell: ({ row }) => {
      // status is complete unless nested transactions have pending/failed statuses
      const hasSuccessNestedTransactions = row.original.transactions?.every(
        (transaction) => orderStatusLookup[transaction.status as OrderStatusKeys] === 'Complete',
      );

      // TODO: make sure Pending status does not show when nested transactions have some failed statuses
      return (
        <Td textAlign="center" justifyItems="center">
          <Flex alignItems="center" {...rest}>
            <CfTag label={hasSuccessNestedTransactions ? 'Complete' : 'Pending'} fontSize="xs" />
          </Flex>
        </Td>
      );
    },
  }),
  columnHelper.display({
    id: 'expander',
    header: () => <Th textAlign="center"></Th>,
    cell: ({ row }) => {
      const hasNestedTransactions = (row.original.transactions?.length || 0) > 0;

      return (
        <Td textAlign="center">
          {hasNestedTransactions && (
            <button
              onClick={() => row.toggleExpanded()}
              style={{
                cursor: 'pointer',
                padding: '4px',
              }}
            >
              <IconCaretDown
                h="1rem"
                w="1rem"
                transform={row.getIsExpanded() ? 'rotate(-180deg)' : 'rotate(0deg)'}
                transition="transform 0.2s ease-in-out"
              />
            </button>
          )}
        </Td>
      );
    },
    size: remToPixels(6),
  }),
];

const RoboTransactionHistoryTable = ({ transactions }: RoboTransactionHistoryTableProps) => {
  const { data, isLoading } = transactions;
  const hasData = Boolean(size(data?.items));

  const table = useReactTable<RoboTransaction>({
    data: data?.items || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: (row) => Boolean(row.original.transactions?.length),
    enableExpanding: true,
  });

  if (isLoading) {
    return (
      <CfCard>
        <CfSpinner />
      </CfCard>
    );
  }

  return (
    <Flex direction="column" gap={4} w="full">
      <CfCard align="center">
        <TableContainer w="full">
          {hasData && (
            <Table variant="simple" fontSize="sm">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Fragment key={header.id}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Fragment>
                    ))}
                  </Tr>
                ))}
              </Thead>

              <Tbody>
                {!hasData ? (
                  <Tr>
                    <Td textAlign="center" colSpan={100}>
                      No transactions found
                    </Td>
                  </Tr>
                ) : (
                  table.getRowModel().rows.map((row) => (
                    <Fragment key={row.id}>
                      <Tr>
                        {row.getVisibleCells().map((cell) => (
                          <Fragment key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Fragment>
                        ))}
                      </Tr>

                      <Tr
                        backgroundColor="rgba(248, 248, 248, 1)"
                        boxShadow="0px 2px 10px -4px rgba(0, 0, 0, 0.1) inset"
                      >
                        <Td colSpan={row.getVisibleCells().length} p={0}>
                          <Collapse in={row.getIsExpanded()}>
                            {row.original.transactions?.map((transaction) => (
                              <Grid
                                key={transaction.orderTxId}
                                w="full"
                                gap={1}
                                templateColumns="repeat(5, 1fr)"
                                py="3"
                                px="4"
                              >
                                <Flex flex="1" px="2">
                                  {/*eslint-disable-next-line react/jsx-newline */}
                                  {row.original.roboTransactionType === 'DEPOSIT' ? 'Buy' : 'Sell'} {transaction.asset}
                                </Flex>

                                <Flex flex="1" justifyContent="right" px="2">
                                  {utcDateToLocal({
                                    dateFormat: dateFormats.dateOnly,
                                    timeString: transaction.transactTime,
                                  })}
                                </Flex>

                                <Flex flex="1" justifyContent="right" px="2">
                                  {formatUsd({ amount: transaction.assetAmount || 0 })}
                                </Flex>

                                <Flex flex="1" justifyContent="right">
                                  <TransactionStatus transaction={transaction} />
                                </Flex>
                              </Grid>
                            ))}
                          </Collapse>
                        </Td>
                      </Tr>
                    </Fragment>
                  ))
                )}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </CfCard>
    </Flex>
  );
};

export default RoboTransactionHistoryTable;
