/* eslint-disable no-restricted-imports */
/**
 * @description Shared custom types for EUX
 *
 * 1. Alpha sort sections
 * 2. Alpha sort types within sections
 * 3. Use camelCase when writing custom types
 * 4. Use Camelize when transforming generated types
 * 5. Decamelize in route handlers as needed
 * 6. When an enum is needed at runtime, export it as a const
 */

import { ChakraComponent, IconProps } from '@chakra-ui/react';
import { CfSelectOption } from '@cryptofi/core-ui';
import { Camelized } from 'humps';
import { ReactElement, SVGProps } from 'react';
import { FieldValues } from 'react-hook-form';

import {
  AccountResponseOutput,
  DbpAuthRequest,
  DbpAuthResponse,
  DbpTokenResponse,
  DividendStatusEnum,
  FinancialInstitutionEducationContentSection,
  KycFieldResponse,
  KycFieldValueTypes,
  KYCStatusEnum,
  OrderAssetTypeEnum,
  OrderSideEnum,
  OrderStatusEnum,
  PeriodicOrderSetting,
  RedisSecurityInfo,
  RedisSecurityPricingInfo,
  RoboRiskToleranceQuestion as RoboRiskQuestion,
  SearchRoboTransactionPaginator,
  SearchTransactionPaginator,
  TelemetryClientSideEventsEnum,
  TokenEnum,
  TokenHistoricalPriceV2 as TokenPriceV2,
  TokenHistoricalPricing,
  TokenNameEnum,
  TokenPriceResponse,
  UrlLink,
  UserPhysicalAddressRequestModel,
  UserValues,
} from '~/codegen/types';

export interface BrandColors {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

//***************************************************************************//
// #region UTILS

export type ValueOf<T> = T[keyof T];
export type Maybe<T> = T | null | undefined;

// #endregion UTILS
//***************************************************************************//

export type ChartTimeRange = keyof InvestmentHistoricalValue;

export type EducationContentSection = Camelized<FinancialInstitutionEducationContentSection>;

export type Product = 'crypto' | 'securities' | 'robo';

export const telemetryEvents = TelemetryClientSideEventsEnum;
export type TelemetryEvents = TelemetryClientSideEventsEnum;

//***************************************************************************//
// #region ASSETS

export type AllAssetIds = CryptoAssetIds | (string & {});

export type AssetBalance = Camelized<import('~/codegen/types').AssetBalance>;

export const assetTypes = OrderAssetTypeEnum;
export type AssetType = 'crypto' | 'securities';

export type TokenPrice = Camelized<TokenPriceResponse>;

export type CryptoAssetIds = keyof typeof tokenIdLookup;

export type SecurityAsset = Camelized<RedisSecurityInfo>;

export type SecurityPrice = Camelized<RedisSecurityPricingInfo>;

export type SelectedAsset = { id: AllAssetIds; type: AssetType };

export type TokenHistoricalPrice = Camelized<TokenHistoricalPricing>;

export type TokenHistoricalPriceV2 = Camelized<{ [key in CryptoAssetIds]: TokenPriceV2 }>;

export const tokenIdLookup = TokenEnum;
export type TokenIdLookup = TokenEnum;

export const tokenNameLookup = TokenNameEnum;
export type TokenNameLookup = TokenNameEnum;

export type InvestmentFilter = { id: string; label: string; matcher: (item: Investment) => boolean };

// #endregion ASSETS
//***************************************************************************//

//***************************************************************************//
// #region AUTH

export type AuthRequest = Camelized<DbpAuthRequest>;

export type AuthResponse = Camelized<DbpAuthResponse>;

export type AuthToken = Camelized<DbpTokenResponse>;

// #endregion AUTH
//***************************************************************************//

//***************************************************************************//
// #region INTRINIO DATA

export type NewsStory = {
  title: string;
  url: string;
  summary: string;
  id: string;
  company: string;
  publicationDate: string;
};

export type PriceIntervalMinutes = '1m' | '5m' | '10m' | '15m' | '30m' | '60m';

interface SecurityCompany {
  id: string;
  ticker?: string;
  name?: string;
  lei?: string;
  cik?: string;
  exchangeTicker?: string;
  figi?: string;
  compositeFigi?: string;
}

interface SecurityDailyMetric {
  date?: string;
  enterpriseValue?: number;
  evToEbit?: number;
  evToEbitda?: number;
  marketCap?: number;
  priceToBook?: number;
  priceToEarnings?: number;
  priceToRevenue?: number;
  priceToTangibleBook?: number;
  dividendYield?: number;
  earningsYield?: number;
  evToInvestedCapital?: number;
  evToRevenue?: number;
  evToNopat?: number;
  evToOcf?: number;
  evToFcff?: number;
}

export type SecurityDailyMetrics = { company?: SecurityCompany; dailyMetrics?: SecurityDailyMetric[] };

interface SecurityInterval {
  time?: string;
  closeTime?: string;
  open?: number;
  close?: number;
  askClose?: number;
  bidClose?: number;
  high?: number;
  low?: number;
  volume?: number;
  interval?: string;
  // average: number; always returns null
  change?: number;
}

export interface SecurityIntervalPrices {
  intervals?: SecurityInterval[];
  security?: SecurityIntervalSummary;
  source?: string;
  nextPage?: string;
}

interface SecurityIntervalSummary {
  id: string;
  companyId?: string;
  stockExchangeId?: string;
  name?: string;
  code?: string;
  currency?: string;
  ticker?: string;
  compositeTicker?: string;
  figi?: string;
  compositeFigi?: string;
  shareClassFigi?: string;
  primaryListing?: boolean;
}

// TODO make these fields optional?
export interface SipData {
  askPrice: number;
  askSize: number;
  askTime: string;
  bidPrice: number;
  bidSize: number;
  bidTime: string;
  closePrice: number | null;
  exchangeVolume: number;
  highPrice: number | null;
  isDarkpool: boolean;
  lastPrice: number;
  lastSize: number;
  lastTime: string;
  listingVenue: string;
  lowPrice: number | null;
  marketCenterCode: string;
  marketVolume: number;
  messages: string[];
  openPrice: number | null;
  quoteConditions: string | null;
  salesConditions: string;
  security: SecurityCompany;
  source: string;
  updatedOn: string;
  qualifiedLastPrice: number;
  qualifiedLastTime: string;
}

// #endregion INTRINIO DATA
//***************************************************************************//

//***************************************************************************//
// #region GLOBAL STORE

interface AuthUserState {
  accessToken?: string;
  expiresIn?: string;
  expiryDate?: number;
  idToken?: string;
  tokenType?: string;
}

export type GlobalStore = {
  userBalancePollingTimestamp: number | null;
  setUserBalancePollingTimestamp: (timestamp: number | null) => void;

  clientOrderIds?: { id: string; timestamp: number }[];
  addClientOrderId: (orderId: string) => void;
  removeClientOrderId: (orderId: string) => void;

  userAuthInfo?: AuthUserState;
  setUserAuthInfo: (args: AuthUserState) => void;

  onboardingProducts: Product[];
  setOnboardingProducts: (products: Product[]) => void;

  onboardingError: string | null;
  setOnboardingError: (error: string | null) => void;
};

// #endregion GLOBAL STORE
//***************************************************************************//

//***************************************************************************//
// #region KYC / ONBOARDING

export type KycAllFields = Camelized<UserValues>;

// transform enum defining types for form inputs to a union of its keys (text, phone, email, etc.)
export type KycFieldTypes = Uncapitalize<keyof typeof KycFieldValueTypes>;

// redefine string[] options to an object with text and value for use in HTML select
export interface KycFormField extends Omit<Camelized<KycFieldResponse>, 'options' | 'valueType' | 'name'> {
  options?: CfSelectOption[];
  valueType: KycFieldTypes;
  name: keyof KycAllFields;
}

// redefine need / have to be arrays with updated options shape
export interface KycFieldGroups {
  need?: KycFormField[];
  have?: KycFormField[];
}

export type KycGroup =
  | 'personalInformation'
  | 'address'
  | 'employment'
  | 'investmentExperience'
  | 'trustedContact'
  | 'contact'
  | 'other';

export type KycStatusLookup = KYCStatusEnum;
export const kycStatusLookup = KYCStatusEnum;

export type OnboardingModalViews =
  | 'welcomeSecurities'
  | 'welcomeCrypto'
  | 'termsAndConditions'
  | 'selectProducts'
  | 'kycInfo'
  | 'kycResults';

export type OnboardingStatus = 'notAcceptedTandC' | 'notStartedKyc' | 'pending' | 'approved' | 'rejected';

export interface OnboardingStep {
  stepNumber: number;
  title: string;
  description: string;
}

export interface OnboardingCardContent {
  title: string;
  description: string;
  Icon: ChakraComponent<(props: IconProps & SVGProps<SVGSVGElement>) => ReactElement<any>>;
  iconProps: IconProps;
}

export type PolicyLink = Camelized<UrlLink>;

export type RiskToleranceLookup = 'Very Conservative' | 'Conservative' | 'Moderate' | 'Aggressive';

export type RoboRiskToleranceQuestion = Camelized<RoboRiskQuestion>;

// #endregion KYC / ONBOARDING
//***************************************************************************//

//***************************************************************************//
// #region TRANSACTION

/**
 * Transactions are NOT stored in the database, but are used to abstract Orders, Dividends, etc. that ARE stored in the database
 */

export type ActiveTransactionHistoryFilter = [keyof TransactionHistoryFormData, string];

export const dividendStatusLookup = DividendStatusEnum;
export type DividendStatusLookup = DividendStatusEnum;

export type FeeBreakdown = Camelized<import('~/codegen/types').FeeBreakdown>;

export type Order = Camelized<import('~/codegen/types').Order>;

export type OrderStatuses = {};

export const orderStatusLookup = OrderStatusEnum;
export type OrderStatusLookup = OrderStatusEnum;

export type RoboTransaction = Camelized<import('~/codegen/types').RoboTransactionOpenSearch>;
export type RoboTransactionTypes = 'deposit' | 'withdrawal' | 'recurring';

export type Transaction = Camelized<import('~/codegen/types').Transaction>;

export type TransactionCurrencies = 'USD' | AllAssetIds;

export interface TransactionHistoryFormData extends FieldValues {
  orderSide?: OrderSideEnum | string;
  token?: TokenIdLookup | string;
  securitiesTicker?: string;
  transactTimeStart?: string;
  transactTimeEnd?: string;
  lastRecordNumber?: number;
}

export type TransactionLimits = Camelized<import('~/codegen/types').TransactionLimits>;

export type TransactionModalViews =
  | 'startTransaction'
  | 'selectAsset'
  | 'reviewTransaction'
  | 'transactionResults'
  | 'searchAssets';

export type TransactionPaginator = Camelized<SearchTransactionPaginator>;

export type RecurringOrder = Camelized<PeriodicOrderSetting>;
export type RecurringOrderPostRequestBody = {
  interval: 'WEEKLY' | 'BIWEEKLY' | 'MONTHLY';
  userSubAcctId: string;
  orderAmount: number;
  weeklyDayOfWeek?: 'SUNDAY' | 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY';
  biweeklyParity?: 'ODD' | 'EVEN';
  monthlyDayOfMonth?: number;
  productType: 'securities' | 'crypto' | 'robo';
  assetType: 'SECURITY' | 'CRYPTO';
  assetIdentifier?: string;
  userRoboAccountId?: string;
  enabled?: boolean;
};

export type RoboTransactionPaginator = Camelized<SearchRoboTransactionPaginator>;

export type TransactionTypes = 'buy' | 'sell';

// #endregion TRANSACTION
//***************************************************************************//

//***************************************************************************//
// #region USER

export type BankAccount = Camelized<AccountResponseOutput>;

export type Investment = {
  assetId: string;
  assetType: AssetType;
  assetName?: string;
  currentPrice?: number;
  avgCost?: number;
  amountOwned?: number;
  sparkline?: { date: string; value: string }[];
  amountUsd?: number;
  delta?: {
    allTime?: { percentChange: number; isGain: boolean; totalGainLoss: number };
    daily?: { percentChange: number; isGain: boolean; totalGainLoss: number };
  };
};

export type InvestmentGroup = {
  assets: Investment[];
  totalAmountUsd: number;
  gainLossAmount: { amount: number; percent: number };
  size: number;
};

export type InvestmentHistoricalValue = Camelized<import('~/codegen/types').InvestmentHistoricalValue>;

export interface TableColumn<T = any> {
  header: string;
  render: (data: T) => React.ReactNode;
  isNumeric?: boolean;
}

// RiskProfileMapping
// UNSET (not onboarded) = -1
// VERY CONSERVATIVE = 1
// CONSERVATIVE = 2
// MODERATE = 3
// AGGRESSIVE = 4
export type RiskProfile = -1 | 1 | 2 | 3 | 4;

export type User = Camelized<import('~/codegen/types').UserResponse>;

export type UserAddress = Camelized<UserPhysicalAddressRequestModel>;

export type UserAssetAllocation = Camelized<import('~/codegen/types').UserAssetAllocation>;

export type UserBalance = { amountAvailable: string | number; assetId: AllAssetIds };

export type UserReport = Camelized<import('~/codegen/types').UserReport>;

export type UserRoboAccountResponse = Camelized<import('~/codegen/types').UserRoboAccountResponse>;

// #endregion USER
//***************************************************************************//
