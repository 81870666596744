'use client';

import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { CfCard, CfSpinner } from '@cryptofi/core-ui';
import { UseQueryResult } from '@tanstack/react-query';
import { size } from 'lodash';

import { TransactionAccordion } from '~/components';
import { RoboTransactionPaginator, TransactionPaginator } from '~/types';
import { isRoboTransaction } from '~/utils';

import RoboTransactionAccordion from './RoboTransactionAccordion';

interface Props extends FlexProps {
  transactions: UseQueryResult<TransactionPaginator | RoboTransactionPaginator | undefined, Error>;
  limit?: number;
}

const TransactionAccordionList = ({ transactions, limit }: Props) => {
  const { data, isLoading } = transactions;
  const hasData = Boolean(size(data?.items));

  if (isLoading) {
    return (
      <CfCard>
        <CfSpinner />
      </CfCard>
    );
  }

  return (
    <Flex w="full">
      {!hasData && (
        <CfCard>
          <Text align="center">No trades found</Text>
        </CfCard>
      )}

      {hasData && (
        <Flex direction="column" justifyContent="center" w="full" gap="2" pb="4">
          {data?.items?.slice(0, limit).map((transaction) => {
            const isRobo = isRoboTransaction(transaction);

            return isRobo ? (
              <RoboTransactionAccordion
                key={transaction.sKey}
                transaction={transaction}
                allowToggle={Boolean(transaction.transactions?.length)}
                initiallyCollapsed
              />
            ) : (
              <TransactionAccordion key={transaction.orderTxId} transaction={transaction} initiallyCollapsed />
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};

export default TransactionAccordionList;
