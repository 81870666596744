import { useCallback, useState } from 'react';

import { Investment, InvestmentFilter } from '../types';

export function useFilter(filterOptions: InvestmentFilter[], initialFilterId?: string) {
  const [activeFilter, setActiveFilter] = useState(initialFilterId ?? filterOptions[0]?.id ?? '');

  const applyFilter = useCallback(
    (items: Investment[]): Investment[] => {
      const selectedOption = filterOptions.find(({ id }) => id === activeFilter);

      if (!selectedOption) {
        return items;
      }
      return items.filter(selectedOption.matcher);
    },
    [activeFilter, filterOptions],
  );

  return {
    activeFilter,
    setActiveFilter,
    applyFilter,
  };
}

export default useFilter;
