'use client';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  ResponsiveValue,
  Table,
  Tbody,
  Text,
  Tr,
} from '@chakra-ui/react';
import { dateFormats, formatUsd, IconCaretDown, uiColors } from '@cryptofi/core-ui';
import dayjs from 'dayjs';

import { LabelTd, TransactionStatus, ValueTd } from '~/components';
import { RoboTransaction } from '~/types';

type RoboTransactionAccordionProps = {
  transaction: RoboTransaction;
  initiallyCollapsed?: boolean;
  variant?: ResponsiveValue<string> | undefined;
  allowToggle?: boolean;
};

const RoboTransactionAccordion = ({
  transaction,
  initiallyCollapsed = false,
  variant,
  allowToggle = true,
}: RoboTransactionAccordionProps) => {
  return (
    <Accordion
      w="full"
      defaultIndex={initiallyCollapsed ? undefined : [0]}
      allowToggle={allowToggle}
      variant={variant || ''}
    >
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              cursor={allowToggle ? 'pointer' : 'auto'}
              _hover={allowToggle ? { bg: uiColors.lighthouse() } : { bg: uiColors.white() }}
            >
              <Flex w="full" justifyContent="space-between" alignItems="center">
                <Text fontFamily="heading">
                  {transaction.roboTransactionType
                    .charAt(0)
                    .toUpperCase()
                    .concat(transaction.roboTransactionType.slice(1).toLowerCase())}
                </Text>

                <Flex direction="column">
                  <Text fontFamily="heading" textAlign="right">
                    {formatUsd({ amount: transaction.roboTransactionAmount || 0 })}
                  </Text>

                  <Text textAlign="right">
                    {dayjs.utc(transaction?.dateAdded).local().format(dateFormats.dateOnly)}
                  </Text>
                </Flex>
              </Flex>

              {allowToggle && (
                <IconCaretDown
                  ml="4"
                  transform={isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'}
                  transition="transform 0.2s ease-in-out"
                  h="1rem"
                  w="1rem"
                  __css={{ path: { fill: uiColors.sonicSilver() } }}
                />
              )}
            </AccordionButton>

            <AccordionPanel background={uiColors.lighthouse()}>
              <Table variant="unstyled">
                <Tbody>
                  {transaction.transactions?.map((subTransaction, index) => (
                    <Tr key={subTransaction.orderTxId || index}>
                      <LabelTd>
                        {/* todo: account for 'rebalance' parent txs. Can we rely on the orderType property in the future? */}
                        {/*eslint-disable-next-line react/jsx-newline */}
                        {transaction.roboTransactionType === 'DEPOSIT' ? 'Buy' : 'Sell'} {subTransaction.asset}
                      </LabelTd>

                      <ValueTd>
                        <TransactionStatus transaction={subTransaction} justifyContent="flex-end" />
                      </ValueTd>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default RoboTransactionAccordion;
