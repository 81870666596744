import { useFeatureSetEnabled, useGetUser } from '~/hooks';
import { hasAcceptedTermsForFeature } from '~/utils';

/**
 *
 * @description Determine if there are updated terms the user has not accepted yet.
 */
const useHasNewTerms = () => {
  const user = useGetUser();
  const { isEnabled } = useFeatureSetEnabled();

  const hasAcceptedCryptoTerms =
    user?.data && isEnabled(['crypto']) && hasAcceptedTermsForFeature({ user: user.data, featureSet: 'crypto' });
  const hasNewCryptoTerms =
    isEnabled(['crypto']) && user?.data?.cryptoKycStatus === 'APPROVED' && !hasAcceptedCryptoTerms;

  const hasAcceptedSecuritiesTerms =
    user?.data &&
    isEnabled(['securities']) &&
    hasAcceptedTermsForFeature({ user: user.data, featureSet: 'securities' });
  const hasNewSecuritiesTerms =
    isEnabled(['securities']) && user?.data?.securitiesKycStatus === 'APPROVED' && !hasAcceptedSecuritiesTerms;

  const hasNewTerms =
    hasNewSecuritiesTerms ||
    hasNewCryptoTerms ||
    ((user?.data?.cryptoKycStatus === 'APPROVED' || user?.data?.securitiesKycStatus === 'APPROVED') &&
      !user?.data?.termsAndConditions?.investifi?.dateAccepted);

  return {
    hasNewTerms,
    hasNewCryptoTerms,
    hasNewSecuritiesTerms,
  };
};

export default useHasNewTerms;
