'use client';

import { Button, Flex, Heading } from '@chakra-ui/react';
import { CfForm, CfInput, CfModal, CfSelect, CfTag, dateToTimestamp, IconClose, uiColors } from '@cryptofi/core-ui';
import { decamelize } from 'humps';
import { get, size } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useFeatureSetEnabled, useGetUser, usePostTelemetryEvent } from '~/hooks';
import { ActiveFilter } from '~/transaction-history/page';
import { telemetryEvents, TransactionHistoryFormData } from '~/types';

interface TransactionHistoryFilterModal {
  clearFilter: (config: { key?: string | undefined; all?: boolean | undefined }) => void;
  isOpen: boolean;
  onClose: () => void;
  setParams: Dispatch<SetStateAction<TransactionHistoryFormData>>;
  txHistoryForm: UseFormReturn<TransactionHistoryFormData>;
  activeFilters: ActiveFilter[];
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const TransactionHistoryFilterModal = ({
  clearFilter,
  isOpen,
  onClose,
  setParams,
  txHistoryForm,
  activeFilters,
  setCurrentPage,
}: TransactionHistoryFilterModal) => {
  const { trackEvent } = usePostTelemetryEvent();
  const { isEnabled } = useFeatureSetEnabled();

  const {
    watch,
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid, isDirty },
  } = txHistoryForm;

  const user = useGetUser();
  const cryptoAssets = Object.keys(get(user, 'data.cryptoSelfDirectedAccount.balance', {})).sort();

  const txHistoryFormData = watch();

  const onSubmit = () => {
    if (!isValid) {
      return;
    }

    try {
      interface FormParams extends TransactionHistoryFormData {
        [key: string]: any;
      }

      const formParams: FormParams = {
        transactionName: txHistoryFormData.transactionName || undefined,
        transactTimeStart: '',
        transactTimeEnd: '',
        assetType: '',
        asset: '',
      };

      if (isEnabled(['securities', 'crypto'])) {
        const transformedAssetType =
          txHistoryFormData.assetType === 'Securities'
            ? 'SECURITY'
            : txHistoryFormData.assetType === 'Crypto'
              ? 'CRYPTO'
              : txHistoryFormData.assetType;
        formParams.assetType = transformedAssetType;
      }
      if (isEnabled(['crypto'])) {
        formParams.asset = txHistoryFormData.asset;
      }

      if (txHistoryFormData.transactTimeStart) {
        formParams.transactTimeStart = dateToTimestamp({
          timeString: txHistoryFormData.transactTimeStart,
        });
      }
      if (txHistoryFormData.transactTimeEnd) {
        formParams.transactTimeEnd = dateToTimestamp({
          timeString: txHistoryFormData.transactTimeEnd,
          toEndDate: true,
        });
      }

      setParams(formParams);
      reset({}, { keepValues: true }); //to reset isDirty
      setCurrentPage(0);

      // Track ClickedTXHistoryAppliedFiltersClient telemetry event with the current form parameters
      // Note: There is a bug that does not allow any param outside of `transactionName` to be sent to Mixpanel;
      // As such, we are filtering out any key in formParams that is not `transactionName`
      const filteredFormParams: Partial<FormParams> = {};
      if ('transactionName' in formParams) {
        filteredFormParams.transactionName = formParams.transactionName;
      }
      if (formParams.orderSide) {
        filteredFormParams.orderSide = formParams.orderSide;
      }
      trackEvent(telemetryEvents.ClickedTXHistoryAppliedFiltersClient, filteredFormParams);

      onClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error applying filters:', error);
      onClose();
    }
  };

  const formId = 'filterForm'; // links the button and form
  const hasActiveFilters = Boolean(size(activeFilters));

  return (
    <>
      <CfModal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        headerContent={
          <Flex justifyContent="center" pos="relative" alignItems="center">
            {hasActiveFilters && (
              <Button
                variant="ghost"
                onClick={() => {
                  clearFilter({ all: true });
                  reset({}, { keepValues: true }); //to reset isDirty
                }}
                pos="absolute"
                top={-1}
                left={-3}
              >
                Clear all
              </Button>
            )}

            <Heading as="h3" size="md">
              Filters
            </Heading>
          </Flex>
        }
        footerContent={
          <Button form={formId} w="full" isDisabled={!isDirty} type="submit">
            Apply filters
          </Button>
        }
      >
        <CfForm onSubmit={handleSubmit(onSubmit)} id={formId} gap="1" w="full">
          {hasActiveFilters && (
            <>
              <Heading as="h4" size="sm" mt="4">
                Active filters
              </Heading>

              <Flex my="4" gap="4" wrap="wrap">
                {activeFilters.map(([key, value]) => (
                  <CfTag
                    key={key}
                    size="sm"
                    py="2"
                    px="4"
                    label={`${decamelize(key as string, { separator: ' ' })}: ${value}`}
                    rightIcon={IconClose}
                    onClick={() => {
                      clearFilter({ key: key as string });
                      reset({}, { keepValues: true }); //to reset isDirty
                    }}
                    _hover={{
                      path: {
                        fill: uiColors.heroicRed(1),
                      },
                    }}
                    cursor="pointer"
                    textTransform="capitalize"
                  />
                ))}
              </Flex>
            </>
          )}

          <Heading as="h4" size="sm">
            Filters
          </Heading>

          <Flex gap="3" py="3" flexDirection={{ base: 'column', md: 'row' }}>
            <CfSelect
              label="Transaction Type"
              name="transactionName"
              register={register}
              errorMessage={errors.transactionName?.message as string}
            >
              <option value="">All</option>

              <option value="Buy">Buy</option>

              <option value="Sell">Sell</option>
            </CfSelect>

            {isEnabled(['securities', 'crypto']) && (
              <CfSelect
                label="Asset Type"
                name="assetType"
                register={register}
                errorMessage={errors.assetType?.message as string}
              >
                <option value="">All</option>

                <option value="Securities">Securities</option>

                <option value="Crypto">Crypto</option>
              </CfSelect>
            )}

            {!isEnabled(['securities']) && isEnabled(['crypto']) && (
              <CfSelect label="Asset" name="asset" register={register} errorMessage={errors.asset?.message as string}>
                <option value="">All</option>

                {cryptoAssets.map((asset) => (
                  <option key={asset} value={asset}>
                    {asset}
                  </option>
                ))}
              </CfSelect>
            )}

            {!isEnabled(['crypto']) && isEnabled(['securities']) && (
              <CfSelect
                label="Asset Type"
                name="assetType"
                register={register}
                errorMessage={errors.assetType?.message as string}
              >
                <option value="">All</option>
              </CfSelect>
            )}
          </Flex>

          <Flex gap="3" py="3" flexDirection={{ base: 'column', md: 'row' }}>
            <CfInput
              label="Start Date"
              type="date"
              name="transactTimeStart"
              register={register}
              errorMessage={errors.transactTimeStart?.message}
            />

            <CfInput
              label="End Date"
              type="date"
              name="transactTimeEnd"
              register={register}
              errorMessage={errors.transactTimeEnd?.message}
            />
          </Flex>
        </CfForm>
      </CfModal>
    </>
  );
};

export default TransactionHistoryFilterModal;
