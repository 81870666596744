import { ComponentWithAs, IconProps } from '@chakra-ui/react';

import IconCrypto101 from '~/components/IconImages/IconCrypto101';
import IconHowToBuySell from '~/components/IconImages/IconHowToBuySell';
import IconInvestmentData from '~/components/IconImages/IconInvestmentData';
import IconInvestorPresentation from '~/components/IconImages/IconInvestorPresentation';
import IconManageMoney from '~/components/IconImages/IconManageMoney';
import IconPiggyBank from '~/components/IconImages/IconPiggyBank';
import IconStatistics from '~/components/IconImages/IconStatistics';

interface Education {
  [key: string]: ComponentWithAs<'svg', IconProps>;
}

const defaultEducationImages: Education = {
  Crypto101: IconCrypto101,
  BankingCryptoAtYourCreditUnion: IconPiggyBank,
  HowToBuyAndSellCryptoAtYourCreditUnion: IconHowToBuySell,
  InvestmentData: IconInvestmentData,
  InvestorPresentation: IconInvestorPresentation,
  ManageMoney: IconManageMoney,
  Statistics: IconStatistics,
};

export default defaultEducationImages;
