'use client';

import { Box, Button, Flex, Heading, ModalProps, Text } from '@chakra-ui/react';
import { CfForm, CfModal, CfSpinner, uiColors } from '@cryptofi/core-ui';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';

import { usePostRiskToleranceQuestionnaire } from '~/hooks';
import { RoboRiskToleranceQuestion } from '~/types';
import { getErrorDetail } from '~/utils';

import { RiskToleranceMultiSelect } from './RiskToleranceMultiSelect';
import { RiskToleranceSelectInput } from './RiskToleranceSelect';

const RiskToleranceQuestionnaireModal = ({
  liquidityProviderAccountId,
  questions,
  onClose,
  isOpen,
  ...rest
}: { liquidityProviderAccountId: string; questions: RoboRiskToleranceQuestion[] } & Omit<ModalProps, 'children'>) => {
  const postRiskToleranceQuestionnaire = usePostRiskToleranceQuestionnaire({
    liquidityProviderAccountId,
  });

  const { handleSubmit, register, formState, setValue } = useForm({
    // no resolver or schema needed, validation is handled in register function on each input component
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const isLoading = formState.isSubmitting || formState.isLoading || postRiskToleranceQuestionnaire.isPending;

  const onSubmit = async (formValues: { [key: string]: string[] | string }) => {
    const body = {
      responses: Object.entries(formValues).map(([questionId, response]) => {
        return {
          questionId,
          responses: Array.isArray(response) ? response : [response],
        };
      }),
    };

    postRiskToleranceQuestionnaire.mutate(body, {
      onSuccess: onClose,
    });
  };

  return (
    <CfModal
      size="2xl"
      closeOnEsc={!formState.isSubmitting}
      closeOnOverlayClick={!formState.isSubmitting}
      onClose={onClose}
      onCloseComplete={() => {}}
      isDisabledCloseButton={formState.isSubmitting}
      isOpen={isOpen}
      headerContent={
        <Heading size="md" textAlign="center">
          Account Recommendations
        </Heading>
      }
      {...rest}
    >
      <Flex flexDir="column" w="full" px={{ base: 0, sm: 10, md: 20 }} py="5">
        <Box
          // uses conditional display to preserve field values in case of server error after submitting
          display={!isLoading ? 'block' : 'none'}
        >
          <Text color={uiColors.sonicSilver()} fontSize="sm" mb="4">
            In order to recommend a portfolio, we need to ask you a few questions. Please complete all fields.
          </Text>

          {postRiskToleranceQuestionnaire.error && (
            <Text color={uiColors.heroicRed()} textAlign="center">
              {getErrorDetail(postRiskToleranceQuestionnaire.error as AxiosError)}
            </Text>
          )}

          <CfForm id="kycForm" onSubmit={handleSubmit(onSubmit)} mt="6">
            <Flex flexDirection="column" gap="6">
              {questions.map((question) =>
                question.allowMultiselect ? (
                  <RiskToleranceMultiSelect
                    key={question.id}
                    question={question}
                    register={register}
                    errorMessage={question.id && (formState.errors?.[question.id]?.message as string)}
                    setValue={setValue}
                  />
                ) : (
                  <RiskToleranceSelectInput
                    key={question.id}
                    question={question}
                    register={register}
                    errorMessage={question.id && (formState.errors?.[question.id]?.message as string)}
                  />
                ),
              )}
            </Flex>

            <Button type="submit" size="lg" isLoading={postRiskToleranceQuestionnaire.isPending} mt={6}>
              Submit
            </Button>
          </CfForm>
        </Box>

        {isLoading && (
          <Flex flexDir="column" gap="4" w="full" alignItems="center">
            <CfSpinner />
          </Flex>
        )}
      </Flex>
    </CfModal>
  );
};

export default RiskToleranceQuestionnaireModal;
