import { useQuery } from '@tanstack/react-query';
import { Camelized, camelizeKeys } from 'humps';

import { RoboRiskToleranceQuestionnaire } from '~/codegen/types';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';

export const qkRiskToleranceQuestionnaireFields = 'qk:riskToleranceQuestionnaireFields';

const useGetRiskToleranceQuestionnaireFields = () => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();

  return useQuery({
    queryKey: [qkRiskToleranceQuestionnaireFields],
    enabled: Boolean(userAuthInfo),
    queryFn: async () => {
      return apiClient?.get(apiRoutes.riskToleranceQuestionnaireFields()).then((fields) => {
        return camelizeKeys(fields.data) as Camelized<RoboRiskToleranceQuestionnaire>;
      });
    },
  });
};

export default useGetRiskToleranceQuestionnaireFields;
